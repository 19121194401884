import SummaryUseCase from '@usecase/dashboard/SummaryUseCase';
import moment from 'moment';
import { showUIToast } from '@/src/core/ui/UIToast';
import { PrivateRoutes } from '@/src/constants/routesConnect';
import { useNavigate } from 'react-router-dom';
import type { BuiltDispositions, BuiltMetric } from '@datasource/contacts-nest/metrics/entities/metrics.entity';
import { Timezone } from '@domain/enums/Users/timezone';

interface SummaryViewModelProps {
  getSummaryData: (callSessionId: string) => Promise<SummaryData | null>;
  getPercentage: (value: number, total: number) => number;
}

interface SummaryData {
  userName: string;
  userAvatar: string;
  userOrganization: string;
  totalContacts: number;
  contactListType: string;
  metrics: BuiltMetric;
  dispositions: BuiltDispositions;
  callSessionId: string;
  contactListName: string;
  dialToConnect: number;
  formattedDate: string;
  formattedTime: string;
  fuegoLead: number;
}

export function SummaryViewModel(): SummaryViewModelProps {
  const navigate = useNavigate();

  const { getCallSession, getBuiltMetrics, getContactListData, getUserAsync, getBuiltDispositions, getOrganization } =
    SummaryUseCase();

  const calculateDialToConnect = (transferred: number | undefined, called: number | undefined): number => {
    if (typeof transferred === 'number' && typeof called === 'number' && called !== 0) {
      return parseFloat(((transferred / called) * 100).toFixed(2));
    }
    return 0;
  };

  const getFormattedDateAndTime = (dateString: string): { formattedDate: string; formattedTime: string } => {
    const formattedDate = moment(dateString).format('dddd, MMMM DD, YYYY');
    const formattedTime = moment(dateString).format('h:mm A');
    return { formattedDate, formattedTime };
  };

  const getSummaryData = async (callSessionId: string): Promise<SummaryData | null> => {
    try {
      const callSessionInfo = await getCallSession(callSessionId);

      if (callSessionInfo?.data === undefined) {
        return null;
      }

      const {
        data: { sdrId, contactList, id: sessionId, startAt },
      } = callSessionInfo;

      const user = {
        id: '',
        name: '',
        email: '',
        userType: [],
        profilePicture: '',
        organizationId: '',
        sdrId: '',
        timezone: Timezone,
      };

      const userInfo = await getUserAsync(sdrId);
      const { data: { organizationId } = {} } = userInfo ?? user;

      const contactListInfo = await getContactListData(contactList[0].contactListId);
      const metricsInfo = await getBuiltMetrics(sessionId);
      const organizationInfo = await getOrganization(organizationId as string);
      const dispositionsInfo = await getBuiltDispositions(sessionId);

      const dialToConnect = calculateDialToConnect(metricsInfo?.data?.transferred, metricsInfo?.data?.called);

      const { formattedDate, formattedTime } = getFormattedDateAndTime(startAt as unknown as string);
      const fuegoLead = contactListInfo?.data?.filter((contact) => contact.flagged)?.length ?? 0;

      const metrics = {
        called: 0,
        answered: 0,
        transferred: 0,
        voicemail: 0,
        not_answered: 0,
        cut_off: 0,
        transfer_error: 0,
        call_ended: 0,
      };

      const dispositions = {
        bad_number_busy_signal: 0,
        opt_out: 0,
        left_vm: 0,
        phone_refusal: 0,
        gatekeeper_conversation: 0,
        referred_another_contact: 0,
        no_longer_with_company: 0,
        set_appointment: 0,
        refuse_transfer: 0,
      };

      return {
        userName: userInfo?.data?.name ?? '',
        userAvatar: userInfo?.data?.profilePicture ?? '',
        userOrganization: organizationInfo?.data?.name ?? '',
        totalContacts: contactListInfo?.data?.length ?? 0,
        contactListType: contactListInfo?.data?.[0]?.type ?? '',
        metrics: metricsInfo?.data ?? metrics,
        dispositions: dispositionsInfo?.data ?? dispositions,
        callSessionId,
        contactListName: contactListInfo?.data?.[0]?.name ?? '',
        dialToConnect,
        formattedDate,
        formattedTime,
        fuegoLead,
      };
    } catch (error) {
      showUIToast({
        type: 'error',
        text: 'Error getting metrics, please try again later.',
      });

      const lastPage = localStorage.getItem('lastDashboardPage') ?? '1';
      const lastSearch = localStorage.getItem('lastDashboardSearch') ?? '';
      navigate(`/${PrivateRoutes.DASHBOARD_V2}?search=${lastSearch}&page=${lastPage}`, { replace: true });

      return null;
    }
  };

  const getPercentage = (value: number, total: number): number => {
    return total !== 0 ? (value / total) * 100 : 0;
  };

  return { getSummaryData, getPercentage };
}
