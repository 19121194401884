import {
  IconDefinition,
  IconName,
  IconPrefix,
} from "@fortawesome/fontawesome-svg-core";

export const customTendrilNewLogo: IconDefinition = {
  icon: [
    24, // SVG view-box width (in pixels), // SVG view-box width (in pixels)
    24, // SVG view-box height (in pixels)
    [], // Aliases                         (no needed)
    "", // Unicode as hex value (no needed)
    "M19.4,25.5c-1.1-.8-2.1-1.5-3.2-2.2.1.5.2,1.2-.2,1.8-.7,1-2.2,1.2-3.6,1.2h-.7l.3.2,4.6,3.2c1-.1,2-.4,2.9-.8.4-.1.8-.3,1.2-.6,0,0,.1,0,.2,0,.4-.2.9-.5,1.3-.8-.9-.6-1.8-1.3-2.8-1.9Z M21.9,5.7c-.7-.5-1.4-.9-2.1-1.2,0,0-.2,0-.3-.1,0,0-.2,0-.3,0l.5-.7c.7-.8-.8-.7-1.1-.6-5.1,1.2-8,1.9-5.9,4.2.6.6,4,3.8,5.1,4,.5,0,0-1.6,0-1.6,0,0,.1,0,.2,0,.2.1.4.2.7.4,2.8,2,3.4,5.8,1.5,8.6-1.1,1.5-2.7,2.4-4.5,2.6l5,3.6c0,0,.1,0,.2-.1,1.3-.8,2.5-1.8,3.4-3.2,3.6-5.1,2.4-12.1-2.7-15.8Z M11.5,17.5c-.5-.2-.5,1.8-.5,1.8-.2-.1-.4-.3-.6-.5-1.6-2.1-1.7-5,0-7.3.8-1.2,2-2,3.3-2.3-.8-.7-1.4-1.3-1.4-1.3-.8-.8-1.1-1.7-.9-2.5.1-.5.5-.9,1-1.3-2.4.7-4.6,2.1-6.1,4.3-3.3,4.7-2.5,11.2,1.7,15l.3.2-.8.6c-.9.6.6.9.8.9,5.2.4,8.2.6,6.9-2.1-.3-.8-2.6-4.9-3.6-5.4Z", // SVG path data
  ],

  iconName: "tendril-connect-listType" as IconName,
  prefix: "custom" as IconPrefix,
};
