import routes from "../../constants/routes";
import axios from "axios";
import { SalesforceReport } from "../../types/SalesforceTypes";

type ReportsProps = {
    lists?: SalesforceReport[];
    error?: { message: string };
};

const getReportsSalesforceAsync = async (
    folderName: string,
    organizationId: string,
    userId: string,
    nonce: string,
): Promise<ReportsProps> => {
    try {
        let url = `${routes.get.salesforce.getReports}/${folderName}/${organizationId}/${userId}/${nonce}`;

        const salesforce = await axios.get<SalesforceReport[]>(url);

        return { lists: salesforce?.data };
    } catch (error) {
        return { error: { message: "Error while getting folders" } };
    }
};

export default getReportsSalesforceAsync;
