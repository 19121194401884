import type { ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';
import routes from '@/src/constants/routes';
import { type RemoveTokenDto } from '@datasource/auth/logout/dto/removeTokenDto';

export async function removeTokenAsync(body: RemoveTokenDto): Promise<ApiResponse<Record<string, string>>> {
  try {
    const postConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = String(routes.post.auth.removeAuthToken);

    const removeToken = await axios.post(url, body, postConfig);
    return { success: true, data: removeToken.data as Record<string, string> };
  } catch {
    return { error: { message: 'Cannot delete keys' } };
  }
}
