import React, { useCallback, useEffect, useState } from "react";
import Notes from "../shared/Notes";
import { Box, Flex, Image, Text } from "../../core/ui";
import { Contact, ContactDialingStatus } from "../shared/Contact";
import SDRPhone from "../shared/SDRPhone";
import { ContactList } from "../shared/CallSession";
import { Device } from "twilio-client";
import styled from "styled-components";
import Note from "../../assets/icons/note.svg";
import { UICol, UIRow } from "../../core/ui/UIStructures";
import UICard from "../../core/ui/UICard";
import { UIButton } from "../../core/ui/UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons/faMicrophoneSlash";
import CallLogTable from "./CallLogTable";
import { showUIToast } from "../../core/ui/UIToast";
import getDialingStatus from "../../api/CallSession/getDialingStatus";
import { Recording } from "../../types/Recording";
import getRecordingByIdAsync from "../../api/Recording/getRecordingByIdAsync";
import playRecordingAsync from "../../api/Recording/playRecordingAsync";

interface Props {
  sdrStatus: string;
  hangup: () => void;
  contactList: [ContactList] | undefined;
  setSdrStatus: (value: string) => void;
  twilioDevice: Device;
  currentContact: Contact | null;
  setCurrentContact: (notes: Contact) => void;
  callId: string | undefined;
  muted: boolean;
  onPhone: boolean;
  voiceDropsIds: [string] | undefined;
  toggleMute: (state: boolean) => void;
  callSessionId: string | undefined;
  speech: string | undefined;
  conferenceFriendlyName: string;
  isPaused: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIntervals: (value: any) => void | undefined;
  submittedInfo: boolean;
  setSubmittedInfo: (submitted: boolean) => void;
  cleanDispositionInfo: () => void;
  submittedDisabled: boolean;
  setSubmittedDisabled: (submittedDisabled: boolean) => void;
  queueElements: number;
  setQueueElements: (queueElements: number) => void;
  friendlyNameVM: string;
}

const LeaveVmModalContainer = styled(Box)`
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
`;

const SpeechContainer = styled.div`
  height: 130px;
  padding: 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    background-color: transparent;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #eeeeee;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border-right: none;
    border-left: none;
    background-color: #a6a8aa;
  }
`;

const CallContainer = ({
  hangup,
  callSessionId,
  contactList,
  muted,
  toggleMute,
  callId,
  voiceDropsIds,
  currentContact,
  setCurrentContact,
  speech,
  onPhone,
  twilioDevice,
  conferenceFriendlyName,
  intervals,
  setIntervals,
  setSubmittedInfo,
  cleanDispositionInfo,
  queueElements,
  setQueueElements,
                         friendlyNameVM,
}: Props): JSX.Element => {
  const [contacts, setContacts] = useState<ContactDialingStatus[]>([]);
  const [recordingVM, setRecordingVM] = useState<Recording>();

  const fetchDialingStatus = useCallback(async () => {
    if (callSessionId !== undefined) {
      const { data, error } = await getDialingStatus({
        callSessionId: callSessionId as string,
      });

      if (data) {
        const formattedData = data.condenseInfo.map((item) => {
          switch (item.action) {
            case "cut_off":
              item.action = "cut off";
              break;
            case "transfer_error":
              item.action = "transfer error";
              break;
          }

          const notes =
            item.notes && (item.notesAgent || item.notesSDR)
              ? `Notes: ${item.notes}\n\n`
              : item.notes
              ? `Notes: ${item.notes}`
              : "";

          const notesAgent = item.notesAgent
            ? `Notes from agent: ${item.notesAgent}`
            : "";

          const notesSDR = item.notesSDR
            ? `Notes from SDR: ${item.notesSDR}`
            : "";

          let finalNote = notes.concat(
            notesAgent === "" && notesSDR === ""
              ? ""
              : notesAgent !== "" && notesSDR === ""
              ? notesAgent
              : notesAgent !== ""
              ? `${notesAgent}\n\n`
              : ""
          );

          finalNote = finalNote.concat(
            item.notesSDR === "" ? "" : `${notesSDR}`
          );

          item["notes"] = finalNote;

          return item;
        });
        setQueueElements(data.queueLength);
        setContacts(formattedData);
      }

      if (error?.status === "404") {
        setContacts([]);
      } else if (error?.status === "") {
        showUIToast({
          type: "error",
          text: "Error getting dialing status, try again later.",
        });
      }
    }
  }, [JSON.stringify(contactList)]);

  useEffect(() => {
    let isMounted = true;

    const newIntervals = intervals;
    if (!currentContact && isMounted) {
      fetchDialingStatus().finally();

      newIntervals["dialingStatus"] = setInterval(() => {
        fetchDialingStatus().finally();
      }, 1000);

      setIntervals(newIntervals);
    }
    return () => {
      isMounted = false;
      clearInterval(newIntervals["dialingStatus"]);
      setSubmittedInfo(true);
    };
  }, [currentContact, fetchDialingStatus]);

  useEffect(() => {
    const recordingId = voiceDropsIds[0];
    const getRecordings = async () => {
        const { success, data } = await getRecordingByIdAsync(recordingId);
        if (success && !!data) {
          setRecordingVM(data);
        }
    };
    getRecordings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { success, error } = await playRecordingAsync({
      friendlyNameVM,
      recordingUrl: recordingVM?.url || "default recording url here",
    });

    error && showUIToast({ type: "error", text: error.message });

    if (success) {
      showUIToast({ type: "info", text: "Playing recording." });
    }
  };

  return (
    <UIRow minHeight="430px">
      {!currentContact ? (
        <UICol>
          <CallLogTable data={contacts} />
        </UICol>
      ) : (
        <>
          <UICol sm={12} md={4} lg={3} gap={0}>
            <UICard title="Dial Pad" width="100%">
              <SDRPhone
                currentContact={{} as Contact}
                hangup={hangup}
                callSessionId={callSessionId}
                onPhone={onPhone}
                twilioDevice={twilioDevice}
              />
            </UICard>
          </UICol>

          <UICol sm={12} md={8} lg={6} alignItems="center" gap={0}>
            <UICard title="Contact Information" width="100%" height="430px">
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={ "55%" }
              >
                <Text
                  color="lightBlue"
                  fontWeight="600"
                  fontSize="clamp(30px, 3vw, 36px)"
                  lineHeight="1.5"
                >
                  {currentContact.name}
                </Text>
                <Text
                  fontSize="clamp(20px, 1.5vw, 24px)"
                  lineHeight="1.5"
                  textAlign="center"
                >
                  {currentContact.accountName} | {currentContact.jobTitle}
                </Text>
                  <Box marginBottom="1rem" textAlign="center">
                    {!!currentContact.email ? (
                      <Text
                        fontSize="clamp(20px, 1.5vw, 24px)"
                        lineHeight="1.5"
                      >
                        {currentContact.phoneExtension? `${currentContact.phone.replace(/[-(). ]|[a-z]+.? ?[0-9]*/g, "")} ext.${currentContact.phoneExtension}` : currentContact.phone.replace(/[-(). ]|[a-z]+.? ?[0-9]*/g, "") } | {currentContact.email}
                      </Text>
                    ) : (
                      <Text
                        fontSize="clamp(20px, 1.5vw, 24px)"
                        lineHeight="1.5"
                      >
                        {currentContact.phone}
                      </Text>
                    )}
                  </Box>
              </Flex>
              {onPhone && (
                <UIRow>
                  <UICol
                    sm={1}
                    gap={0}
                    alignItems="flex-start"
                    justifyContent="flex-end"
                  >
                    <Image src={Note} alt="Note" width="15px" />
                  </UICol>
                  <UICol gap={0}>
                    <SpeechContainer data-qa-id={"notesInputContainer"}>
                      <pre className="whitespace-pre-wrap">{speech}</pre>
                    </SpeechContainer>
                  </UICol>
                </UIRow>
              )}
                <UIRow alignItems="center" justifyContent="center">
                  <UICol justifyContent="center" gap={1}>
                    <UIButton
                      onClick={handleSubmit}
                      disabled={!onPhone}
                    >
                      Leave VM
                    </UIButton>

                    <UIButton
                      onClick={() => toggleMute(!muted)}
                      disabled={!onPhone}
                    >
                      <FA
                        icon={
                          !!callId && !muted ? faMicrophone : faMicrophoneSlash
                        }
                      />
                    </UIButton>
                  </UICol>
                </UIRow>
            </UICard>
          </UICol>
          <UICol sm={12} md={12} lg={3}>
            <UICard title="Notes" width="100%" height="430px">
              <Notes
                canDelete
                currentContact={currentContact}
                setCurrentContact={setCurrentContact}
                isSDR={true}
                showHeader={false}
              />
            </UICard>
          </UICol>
        </>
      )}
    </UIRow>
  );
};

export default CallContainer;
