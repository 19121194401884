import routes from "../../constants/routes";
import axios from "axios";

export type SdrsResponse = {
  id: string;
  _id: string;
  name: string;
};

type ResponseProps = {
  allSdrs?: SdrsResponse[] | [];
  error?: { message: string };
};

const getSdrsAsync = async (
  userId: string,
  userIds: string[] | null,
  organizationId: string | null,
): Promise<ResponseProps> => {
  try {
    const sdrs = await axios.get<SdrsResponse[]>(
      `${routes.get.users.allActiveSdrsNest}/${userId}`,
      {
        params: {
          userIds: userIds,
          organizationId: organizationId,
        },
      }
    );
    return { allSdrs: sdrs.data };
  } catch {
    return { error: { message: "Error getting all sdrs" } };
  }
};

export default getSdrsAsync;
