export type Account = {
  id: string;
  type: string;
  email: string;
  nonce: string;
  name?: string;
  attributes?: Attributes;
};

export type Attributes = {
  name: string;
  activeProspectsCount: number;
};

export enum AccountType {
  Outreach = "outreach",
  Hubspot = "hubspot",
  Custom = "Custom",
  Salesforce = "salesforce"
}
