import routes from "../../constants/routes";
import axios from "axios";

type ResponseProps = {
  code?: string;
  error?: { message: string };
};

const registreNumberCaller = async (
  number: string,
  sdrId: string
): Promise<ResponseProps> => {

  try {
    const twilioResponse = await axios.post<string>(
      `${routes.post.callerId.createNumber}/${sdrId}`,
      { number: number }
    );
    return { code: twilioResponse.data };
  } catch (e: any) {
    return { error: { message: "Error trying to create user number: " + number } };
  }
};

export default registreNumberCaller;
