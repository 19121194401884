import getContactsByCallSessionIds from '../../../DataSource/contacts-nest/contacts/getContactsByCallSessionId';
import { type Contact, type ContactInfo } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type SelectedFilters } from '@/src-v2/Domain/interfaces/Contacts/filter.interfaces';
import updateContactAsync from '@/src-v2/Data/DataSource/contacts-nest/contacts/updateContactAsync';
import getContactsByContactListIdAsync from '@datasource/contacts-nest/contacts/getContactsByContactListId';
import { getContactByIdAsync } from '@datasource/contacts-nest/contacts/entities/getContactById';

export interface ContactsRepo {
  getContactsByCallSessionIds: (
    userId: string,
    callSessionIds: string[],
    filters: SelectedFilters,
  ) => Promise<ApiResponse<Contact[]>>;
  getContactsByContactListId: (contactListId: string) => Promise<ApiResponse<ContactInfo[]>>;
  updateContactAsync: (contact: Contact) => Promise<ApiResponse<Contact>>;
  getContactById: (contactId: string) => Promise<ApiResponse<ContactInfo>>;
}

export function ContactsRepository(): ContactsRepo {
  return {
    async getContactsByCallSessionIds(userId: string, callSessionIds: string[], filters: SelectedFilters) {
      const { data, error, success } = await getContactsByCallSessionIds(userId, callSessionIds, filters);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getContactsByContactListId(contactListId: string): Promise<ApiResponse<ContactInfo[]>> {
      const { data, error, success } = await getContactsByContactListIdAsync(contactListId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async updateContactAsync(contact: Contact) {
      const { data, error, success } = await updateContactAsync(contact);
      return { data, error, success };
    },

    async getContactById(contactId: string): Promise<ApiResponse<ContactInfo>> {
      const { data, error, success } = await getContactByIdAsync(contactId);
      if (data == null || error != null) {
        return { error, success: false };
      }

      return { data, success };
    },
  };
}
