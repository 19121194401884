import React, { ReactNode } from "react";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";
import { titleKey } from "../utils";
import Loader from "./UILoader";
const { colors } = theme;
const { lightBlue, darkBlue } = colors;

const Tabs = styled.section<{
  maxWidth: string;
  height?: string;
  isSettings: boolean;
}>`
  display: grid;
  grid-template-rows: auto 1fr;
  width: calc(100% - 4rem);
  margin-right: ${({ isSettings }) => (isSettings ? "4rem" : "")};
  ${({ maxWidth }) => maxWidth && { maxWidth }};
  ${({ height }) => height && { height }};
  width: 100%;  
`;

const TabsHeader = styled.div<{ isSettings: boolean }>`
  display: flex;
  justify-content: ${({ isSettings }) => (isSettings ? "flex-end" : "initial")};
  width: 100%; 
`;

const TabButton = styled.button<{ isSettings: boolean }>`
  flex: ${({ isSettings }) => (isSettings ? "" : "1")};
  width: ${({ isSettings }) => (isSettings ? "20%" : "")};
  padding: 0.75em;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  font-size: ${({ isSettings }) => (isSettings ? "0.9rem" : "1.2rem")};
  color: ${({ isSettings }) => (isSettings ? "#b6b7b9" : "white")};
  background-color: ${({ isSettings }) =>
    isSettings ? "#fff" : `${lightBlue}`};
  &:first-of-type {
    border-top-left-radius: 0.5rem;
  }
  &:last-of-type {
    border-top-right-radius: 0.5rem;
  }
  &:disabled {
    cursor: initial;
  }
  &.selected {
    background-color: ${({ isSettings }) => (isSettings ? "" : `${darkBlue}`)};
    color: ${({ isSettings }) => (isSettings ? "#31a9f9" : `white`)};
  }
  &:not(:disabled) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

const TabsContent = styled.div<{
  hasLoading?: boolean;
  isSettings: boolean;
}>`
  ${({ hasLoading }) => hasLoading && "min-height: 400px;"};
  margin-top: ${({ isSettings }) => (isSettings ? "4rem" : "")};
  position: relative;
  color: ${colors.darkGray};
  background-color: white;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export type Tab = {
  id: string;
  header: string;
  content: (i: string) => ReactNode;
  callback?: () => any;
};

type TabsProps = {
  title: string;
  tabs: Tab[];
  initialTab?: string;
  maxWidth?: string;
  height?: string;
  loading?: boolean;
  loadingMessage?: string;
  isSettings: boolean;
};

export const UITabbedContainer = (props: TabsProps): JSX.Element => {
  const {
    title,
    tabs,
    maxWidth = "920px",
    initialTab = tabs[0].id,
    height,
  } = props;
  const [selected, select] = useState(initialTab);
  return (
    <Tabs
      aria-label={title}
      maxWidth={maxWidth}
      height={height}
      isSettings={props.isSettings}
    >
      <TabsHeader isSettings={props.isSettings}>
        {tabs.map(({ id, header, callback }) => (
          <TabButton
            key={id}
            disabled={!(tabs.length > 1)}
            className={`${selected === id && "selected"}`}
            onClick={() => {
              select(id);
              if (callback) callback();
            }}
            isSettings={props.isSettings}
          >
            {header}
          </TabButton>
        ))}
      </TabsHeader>
      <TabsContent hasLoading={props.loading} isSettings={props.isSettings}>
        {props.loading ? (
          <Loader message={props.loadingMessage || ""} />
        ) : (
          tabs.map(
            ({ id, content }, index) =>
              id === selected && content(titleKey(title, index))
          )
        )}
      </TabsContent>
    </Tabs>
  );
};
