import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@components/ui/sheet';
import React, { type FC } from 'react';
import { Button } from '@components/ui/button';
import {
  type ContactInfo,
  ContactSource,
  ContactToDisplay,
  NotesToDisplay,
} from '@datasource/contacts-nest/contacts/entities/contact.entity';
import renderMappedIcon from '@/src-v2/Presentation/Common/Components/customElements/ContactIconTypeMap';
import UILoader from '@/src-v2/Presentation/components/shared/UILoader';
import { DashboardDetailsViewModel } from '@views/Dashboard/Components/DashboardDetails.viewmodel';

interface ContactInfoSheetProps {
  contact: Omit<ContactInfo, 'id' | 'contactsListId' | 'flagged'>;
  triggerElement: React.JSX.Element;
  triggerButtonStyles: string;
}

const ContactInfoSheet: FC<ContactInfoSheetProps> = ({
  contact,
  triggerElement,
  triggerButtonStyles,
}: ContactInfoSheetProps) => {
  const { name, phone, jobTitle, email, accountName, notes, notesSDR, notesAgent, meta } = contact;
  const bolderTextStyle = 'font-bold text-app-gray-500 mt-1';
  const lightTextStyle = 'text-app-gray-400';
  const gridBlock = 'grid grid-rows[30px_30px] justify-items-center';
  const { loading } = DashboardDetailsViewModel();
  let hasCRMId = false;

  switch (contact.type) {
    case ContactSource.CSV:
      hasCRMId = false;
      break;
    case ContactSource.OUTREACH:
    case ContactSource.HUBSPOT:
      hasCRMId = true;
      break;
  }
  const contactData = new ContactToDisplay(accountName, email, name, phone, jobTitle);
  const notesData = new NotesToDisplay(notes, notesSDR, notesAgent);
  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className={triggerButtonStyles}>{triggerElement}</div>
      </SheetTrigger>
      {loading ? (
        <SheetContent>
          <UILoader message={'Loading Contact details, please wait...'} size="1/2" />
        </SheetContent>
      ) : (
        <SheetContent className="w-[400px] sm:w-[540px] md:w-[768px] space-y-8">
          <SheetHeader>
            <SheetTitle>Contact</SheetTitle>
          </SheetHeader>
          <div className="flex flex-row gap-8 items-start justify-center font-bold mb-2 ">
            <div className={gridBlock}>
              <h4 className="text-app-gray-500">Info from contact</h4>
              <h4 className="text-app-blue-lighter">{contact.name}</h4>
            </div>
            <div className={gridBlock}>
              <h4 className="text-app-gray-500">Source</h4>
              {renderMappedIcon(contact.type)}
            </div>
            {hasCRMId ? (
              <div className={gridBlock}>
                <h4 className="text-app-gray-500">CRM id:</h4>
                <h4 className="text-app-blue-lighter">
                  {contact.crmId !== '' && contact.crmId !== undefined ? contact.crmId : meta?.externalId}
                </h4>
              </div>
            ) : null}
          </div>
          <main className="grid gap-2 max-[1100px]:max-height-[500px]">
            {Object.entries(contactData).map((entry) => {
              const displayName = entry[0];
              const value = String(entry[1]);
              const key = entry[0].split(' ').join('_').toLowerCase();
              return (
                <div className="flex flex-col" key={key}>
                  <h4 className={bolderTextStyle}>{displayName}</h4>
                  <h5 className={lightTextStyle}>{value}</h5>
                </div>
              );
            })}

            {Object.entries(notesData).map((entry) => {
              const displayName = entry[0];
              const value = String(entry[1]);
              const key = entry[0].split(' ').join('_').toLowerCase();
              return (
                <div key={key} className="flex flex-row gap-2 justify-between items-center">
                  <div className="min-w-[48px]">
                    <h4 className={bolderTextStyle}>{displayName}</h4>
                  </div>
                  <div className="max-w-[254px] max-h-[77px] overflow-y-auto">
                    <h5 className={`text-end hover:bg-sky-50  ${lightTextStyle}`}>{value}</h5>
                  </div>
                </div>
              );
            })}
          </main>
          <SheetFooter>
            <SheetClose asChild>
              <Button type="submit">Close</Button>
            </SheetClose>
          </SheetFooter>
        </SheetContent>
      )}
    </Sheet>
  );
};

export default ContactInfoSheet;
