import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type ActiveOrganizations } from '@datasource/edge/organizations/organizations';

async function getOrganizationById(organizationId: string): Promise<ApiResponse<ActiveOrganizations>> {
  try {
    const organization: ApiResponse<ActiveOrganizations> = await axios.get(
      `${routes.get.organizations.getById}/${organizationId}`,
    );

    return { data: organization.data, success: true };
  } catch (e) {
    return { error: { message: 'Error getting organization by Id' }, success: false };
  }
}

export default getOrganizationById;
