import routes from "../../constants/routes";
import axios from "axios";
import { Account } from "../../pages/Settings/Shared/Entities/Account";

type AccountsProps = {
  accounts?: Account[];
  error?: { message: string };
};

const getSalesforceAccountsAsync = async (
  userId: string,
  orgId: string,
): Promise<AccountsProps> => {
  try {
    const salesforceAccounts = await axios.get<Account[]>(
      `${routes.get.salesforce.getLinkedAccounts}/${orgId}/${userId}`
    );

    return { accounts: salesforceAccounts?.data };
  } catch (error) {
    return { error: { message: "Error while getting contacts" } };
  }
};

export default getSalesforceAccountsAsync;
