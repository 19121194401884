import { showUIToast } from '@/src/core/ui/UIToast';
import CalendarRepository from '@repository/edge/calendar/calendarRepository';
import { useOutletContext } from 'react-router-dom';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import { type CalendarResponse, type CalendarSlot } from '@datasource/edge/calendar/entities/calendar.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import moment from 'moment-timezone';
import { Timezone, TimezoneMoment } from '@domain/enums/Users/timezone';

interface CalendarViewModelProps {
  getCalendlyScheduleSessionsData: (cache: boolean) => Promise<CalendarResponse>;
}

export function CalendarViewModel(): CalendarViewModelProps {
  const { getCalendlySessions } = CalendarRepository();

  const {
    userSession: { userId: id, timezone },
  } = useOutletContext<OutletContextProps>();

  const getCalendlyScheduleSessionsData = async (cache: boolean): Promise<CalendarResponse> => {
    try {
      const data: ApiResponse<CalendarResponse> = await getCalendlySessions(id, cache);

      if (data?.data?.data !== undefined && Array.isArray(data.data.data)) {
        const validSlots: CalendarSlot[] = data.data.data.map((slot) => {
          // Convert the timezone of start/end times to the user's timezone
          const correctTimezone = getMomentTimezone(timezone);
          const startDate = moment.tz(slot.start, correctTimezone).format('MMMM Do YYYY, h:mm a');
          const endDate = moment.tz(slot.end, correctTimezone).format('MMMM Do YYYY, h:mm a');
          return {
            ...slot,
            start: startDate,
            end: endDate,
          } satisfies CalendarSlot;
        });
        return { date: data.data.date, data: validSlots };
      }

      return { date: data?.data?.date as string, data: [] as CalendarSlot[] };
    } catch (error) {
      showUIToast({
        type: 'error',
        text: 'Error fetching Calendly events. Please try again later.',
      });
      return { date: new Date().toISOString(), data: [] as CalendarSlot[] };
    }
  };

  const getMomentTimezone = (timezone: string): string => {
    const oldTimezone = Object.entries(Timezone).find(([_, value]) => value === timezone)?.[0];

    const updatedTimezone = oldTimezone !== null ? TimezoneMoment[oldTimezone as keyof typeof TimezoneMoment] : 'UTC';

    return updatedTimezone;
  };

  return { getCalendlyScheduleSessionsData };
}
