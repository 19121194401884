import { type Contact } from '@/src-v2/Data/DataSource/contacts-nest/contacts/entities/contact.entity';
import { Checkbox } from '@/src-v2/Presentation/Common/Components/ui/checkbox';
import { ContactsUseCase } from '@usecase/contacts-nest/ContactsUseCase';
import React, { useEffect, useState } from 'react';
import { UserAvatarImage } from '@views/Settings/Account/Components/UserAvatarImage.component';
import Fuego from '../../../../../src/assets/icons/tendril-fuego-icon.svg';

interface FlaggedFilterProps {
  contact: Contact;
  disabled?: boolean;
}

export const FlaggedFilter: React.FC<FlaggedFilterProps> = ({ contact, disabled }) => {
  const contactsUseCase = ContactsUseCase();

  const [flagged, setFlagged] = useState<boolean>(contact.flagged);

  useEffect(() => {
    if (disabled === true) {
      setFlagged(false);
      contactsUseCase.updateContactAsync({ ...contact, flagged: false }).catch((error) => {
        console.error('Error updating contact with flagged false:', error);
      });
    }
  }, [disabled, contact]);

  const handleCheckboxChange = async (isFlagged: boolean): Promise<void> => {
    if (disabled === true) return;

    setFlagged(isFlagged);
    try {
      await contactsUseCase.updateContactAsync({ ...contact, flagged: isFlagged });
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };

  return (
    <div className="flex items-center space-x-2 ml-3">
      <Checkbox
        id="flagged"
        checked={flagged}
        disabled={disabled}
        onCheckedChange={(e: boolean): void => {
          void handleCheckboxChange(e);
        }}
      />
      <UserAvatarImage
        src={Fuego as unknown as string}
        twClass={'object-fill rounded md:w-8 md:h-8 xl:h-8 xl:w-8 relative padding-auto'}
      />
      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        Fuego lead
      </label>
    </div>
  );
};
