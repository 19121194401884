import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@components/ui/alert-dialog';
import { Button } from '@components/ui/button';
import { IconFileTypeCsv, IconX } from '@tabler/icons-react';
import React, { type FC } from 'react';
import { DashboardViewmodel } from '@views/Dashboard/Dashboard.viewmodel';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@components/ui/tooltip';
import { CallSessionRepository } from '@repository/contacts-nest/callSession/callSessionRepository';
import { rebuildContactsCSV } from '@domain/helpers/csvRebuilder.helper';
import { ContactsRepository } from '@repository/contacts-nest/contacts/contactsRepository';

interface ReportsModalProps {
  callSessionId: string;
  type: string;
}

async function getSessionList(callSessionId: string): Promise<string | undefined> {
  const { getCallSessionById } = CallSessionRepository();
  const callSession = await getCallSessionById(callSessionId);
  if (callSession.data !== undefined) {
    const { contactList } = callSession.data;
    return contactList[0].contactListId;
  }
}

const ReportsModalComponent: FC<ReportsModalProps> = ({ callSessionId, type }): React.JSX.Element => {
  const { generateReport } = DashboardViewmodel();

  function getCSVfromSession(): void {
    void getSessionList(callSessionId).then((listId) => {
      void rebuildContactsCSV(listId as string, ContactsRepository());
    });
  }

  return (
    <AlertDialog>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <AlertDialogTrigger asChild>
              <Button id="showReportDownloadModal" variant="outline" size="icon" className="border-none">
                <IconFileTypeCsv />
              </Button>
            </AlertDialogTrigger>
          </TooltipTrigger>
          <TooltipContent>Download report</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <AlertDialogContent className="px-2 py-4">
        <AlertDialogHeader className={'flex items-center'}>
          <div className="flex flex-row items-center gap-60 justify-between">
            <AlertDialogTitle className="text-app-blue-light">Download report</AlertDialogTitle>
            <AlertDialogCancel className="border-none">
              <IconX color="hsl(var(--primary))" />
            </AlertDialogCancel>
          </div>

          <AlertDialogDescription className="border-t-gray-500 my-8 grid gap-3">
            <hr />
            <h2 className="text-app-gray-500 text-center mb-4 font-bold">Choose an export type</h2>
            <ul className="text-app-gray-400">
              {type === 'CSV' && (
                <li>
                  <b>Original:</b> Download the originally uploaded CSV list.
                </li>
              )}
              <li>
                <b>Raw:</b> Includes all the stages of each call.
              </li>
              <li>
                <b>Compact:</b> Includes only the last status of each call.
              </li>
            </ul>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="flex flex-row items-center gap-14 justify-center">
          {type === 'CSV' && (
            <AlertDialogAction
              className="bg-gradient-to-l from-app-blue-light to-app-blue-lighter text-white w-[75px] p-1"
              onClick={() => {
                getCSVfromSession();
              }}
            >
              Original
            </AlertDialogAction>
          )}
          <AlertDialogAction
            className="bg-gradient-to-l from-app-blue-light to-app-blue-lighter text-white w-[75px] p-1"
            onClick={() => {
              generateReport(callSessionId, 'raw');
            }}
          >
            Raw
          </AlertDialogAction>
          <AlertDialogAction
            className="bg-gradient-to-l from-app-blue-light to-app-blue-lighter text-white w-[75px] p-1"
            onClick={() => {
              generateReport(callSessionId, 'compact');
            }}
          >
            Compact
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ReportsModalComponent;
