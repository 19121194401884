import React from 'react';
import CalendarComponent
	from "../../../../src-v2/Presentation/Views/Management/Components/Calendar/Calendar.component";

const CalendarManagementContainer = () => {
	return (
		<>
			<CalendarComponent />
		</>
	);
};

export default CalendarManagementContainer;
