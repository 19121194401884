import Papa from 'papaparse';
import { type CSVContact } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import { type ContactsRepo } from '@repository/contacts-nest/contacts/contactsRepository';

/**
 * Gets all contacts from a Contact List and filters its fields to recover the CSV as it was originally uploaded
 * uses API calls from v2 (only Contacts Repository).
 **/
export async function rebuildContactsCSV(contactListId: string, contactsRepo: ContactsRepo): Promise<void> {
  const { getContactsByContactListId } = contactsRepo;
  const contactsByListId = await getContactsByContactListId(contactListId);
  if (contactsByListId.data !== undefined) {
    const csvBody = contactsByListId.data.reduce((accumulator: Array<Partial<CSVContact>>, currentValue) => {
      const { name, phone, jobTitle, email, accountName, notes, crmId } = currentValue;
      accumulator.push({ name, phone, jobTitle, email, accountName, notes, crmId });
      return accumulator;
    }, []);

    const csv = Papa.unparse(csvBody);

    const objectURL = window.URL.createObjectURL(new Blob([csv]));
    const downloadAnchor = document.createElement('a');

    downloadAnchor.href = objectURL;
    downloadAnchor.setAttribute('download', `list-${contactListId}.csv`);

    document.body.appendChild(downloadAnchor);

    downloadAnchor.click();
  }
}
