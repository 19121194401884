import routes from "../../constants/routes";
import axios from "axios";
import {
  CallSession,
  CreateCallSessionResponse,
} from "../../components/shared/CallSession";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

type Props = {
  data: CallSession | null;
};

type dataType = {
  callSessionId: string | null;
};

type ResponseProps = {
  responseCreateCallSession?: CreateCallSessionResponse | null;
  error?: {
    message: string;
    data: dataType | null;
  };
};

const createCallSessionAsync = async ({
  data,
}: Props, random : boolean): Promise<ResponseProps> => {
  try {
    data.random = random;
    const callSessionResponse = await axios.post<CreateCallSessionResponse>(
      routes.post.callSession.createNest,
      data
    );
    return { responseCreateCallSession: callSessionResponse.data };
  } catch (e: any) {
    const message = "Something went wrong creating the call session";

    if (e!.response.data.data) {
      logAxiosErrorWithSentry({
        payload: e,
        description:
          "Error creating a new call session, there are an active call session",
      });
    }

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }

    return { error: { message, data: e!.response.data.data } };
  }
};

export default createCallSessionAsync;
