import React, { useEffect, useState, memo } from 'react';
import { useOutletContext } from 'react-router-dom';
import type { OutletContextProps } from '@/src/components/RoutesLayout';
import UILoader from '@/src-v2/Presentation/components/shared/UILoader';
import { CalendarViewModel } from '@views/Management/Components/Calendar/Calendar.viewmodel';
import { showUIToast } from '@/src/core/ui/UIToast';
import { IconRefresh, IconCalendarWeek } from '@tabler/icons-react';
import { type CalendarSlot } from '@datasource/edge/calendar/entities/calendar.entity';
import { formatDistanceToNow } from 'date-fns';

// Interface for props passed to ReloadButton
interface ReloadButtonProps {
  cachedTime: string; // The time the data was last cached (ISO string format)
  onReload: () => void; // Callback to handle the reload action
}

// Memoized button component to display the elapsed time
const ReloadButton: React.FC<ReloadButtonProps> = memo(({ cachedTime, onReload }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const updateElapsedTime = () => {
      if (cachedTime !== '' && isValidDate(cachedTime)) {
        const formattedTime = formatDistanceToNow(new Date(cachedTime), { addSuffix: true });
        setTimeAgo(formattedTime);
      } else {
        console.error('Invalid cachedTime value:', cachedTime);
        setTimeAgo('Unknown time');
      }
    };

    updateElapsedTime();
    const interval = setInterval(updateElapsedTime, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [cachedTime]);

  const isValidDate = (date: string): boolean => !isNaN(new Date(date).getTime());

  return (
    <button
      className="px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-medium text-sm lg:text-base rounded-lg shadow-md hover:shadow-lg hover:from-blue-600 hover:to-blue-800 transition-transform duration-200 transform hover:-translate-y-1 hover:scale-105 flex items-center gap-2"
      onClick={onReload}
    >
      <IconRefresh stroke={2} />
      <span>{timeAgo}</span>
    </button>
  );
});

// Interface for the Header component that includes the button
interface HeaderProps {
  cachedTime: string; // The time the data was cached
  handleReload: () => void; // Handler function to reload the data
}

// Header component (memoized) with responsive styles
const Header: React.FC<HeaderProps> = ({ cachedTime, handleReload }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-6 mb-8 px-4 lg:px-8">
      {/* Header Title */}
      <h2 className="text-2xl lg:text-4xl font-bold text-center text-gray-800">
        Booked Slots <span className="text-blue-600">(Next 2 Months)</span>
      </h2>

      {/* Integrate the reload button */}
      <ReloadButton cachedTime={cachedTime} onReload={handleReload} />
    </div>
  );
};

// Main calendar rendering component with responsive layout (memoized)
const RenderCalendar = memo(({ groupedSlots }: { groupedSlots: Record<string, CalendarSlot[]> }) => (
  <div
    className="overflow-y-auto max-h-[500px] px-4 lg:px-8 pb-4 bg-white shadow-lg rounded-lg border border-gray-200"
    key={Object.keys(groupedSlots).join('-')}
  >
    {Object.keys(groupedSlots).length === 0 ? (
      <p className="text-center text-gray-500 text-base">No appointments scheduled for this period.</p>
    ) : (
      Object.entries(groupedSlots).map(([date, slots]) => (
        <div key={date} className="mb-10">
          {/* Header for each group of slots */}
          <h3 className="text-lg lg:text-xl font-semibold text-white bg-blue-600 rounded-md px-4 py-2 sticky top-0 z-10 shadow-md border border-blue-400 flex items-center gap-2">
            <span className="inline-flex items-center justify-center w-7 h-7 bg-white text-blue-600 font-bold rounded-full shadow">
              <IconCalendarWeek stroke={2} />
            </span>
            {date}
          </h3>

          {/* Cards for each slot */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-4">
            {slots.map((slot, index) => (
              <div
                key={`${slot.start}-${index}`}
                className="bg-gray-50 p-6 shadow-md rounded-md border border-gray-300 flex flex-col gap-4"
              >
                <p className="text-sm lg:text-base text-gray-600">
                  🕒 <strong className="text-gray-800">Start:</strong>{' '}
                  <span className="font-semibold text-gray-900">{slot.start.split(',')[1]?.trim()}</span>
                </p>
                <p className="text-sm lg:text-base text-gray-600">
                  ⏳ <strong className="text-gray-800">End:</strong>{' '}
                  <span className="font-semibold text-gray-900">{slot.end.split(',')[1]?.trim()}</span>
                </p>
                <p className="text-sm lg:text-base text-gray-600">
                  📋 <strong className="text-gray-800">Status:</strong>{' '}
                  <span
                    className={
                      slot.status === 'active'
                        ? 'text-green-700'
                        : slot.status === 'canceled'
                        ? 'text-red-700'
                        : 'text-black-600'
                    }
                  >
                    {slot.status.charAt(0).toUpperCase() + slot.status.slice(1)}
                  </span>
                </p>
                {slot?.canceledBy !== undefined && (
                  <p className="text-sm lg:text-base text-gray-600">
                    ❌ <strong className="text-gray-800">Cancelled by:</strong>{' '}
                    <span className="text-gray-800">{slot.canceledBy}</span>
                  </p>
                )}
                <p className="text-sm lg:text-base text-gray-600">
                  🏠 <strong className="text-gray-800">Host:</strong> <span className="text-blue-700">{slot.host}</span>
                </p>
                <p className="text-sm lg:text-base text-gray-600">
                  ✉️ <strong className="text-gray-800">Guests:</strong>{' '}
                  <span className={slot.guests === 'No guests' ? 'text-gray-600' : 'text-blue-700'}>{slot.guests}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      ))
    )}
  </div>
));

// Main CalendarComponent
const CalendarComponent: React.FC = () => {
  const {
    userSession: { email },
  } = useOutletContext<OutletContextProps>();

  const { getCalendlyScheduleSessionsData } = CalendarViewModel();

  const [groupedSlots, setGroupedSlots] = useState<Record<string, CalendarSlot[]>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [cachedTime, setCachedTime] = useState<string>('');

  // Fetch calendar data when email is available
  useEffect(() => {
    const fetchCalendarData = async (): Promise<void> => {
      if (email !== '') {
        setLoading(true);
        try {
          const data = await getCalendlyScheduleSessionsData(false);
          console.log(data, 'data');

          const { date, data: slots } = data;
          setCachedTime(date);

          if (Array.isArray(slots)) setGroupedSlots(processCalendarData(slots));
        } catch (error) {
          showUIToast({
            type: 'error',
            text: 'Error fetching Calendly events. Please try again later.',
          });
        } finally {
          setLoading(false);
        }
      }
    };
    fetchCalendarData().catch(() => {
      setLoading(false);
    });
  }, [email]);

  // Group calendar data by date
  const processCalendarData = (data: CalendarSlot[]): Record<string, CalendarSlot[]> => {
    return data.reduce<Record<string, CalendarSlot[]>>((acc, slot) => {
      const dateKey = slot.start.split(',')[0]?.trim();
      if (acc[dateKey] === undefined) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(slot);
      return acc;
    }, {});
  };

  // Reload calendar data manually
  const reload = async (): Promise<void> => {
    if (email === '') {
      return;
    }
    setLoading(true);
    try {
      const data = await getCalendlyScheduleSessionsData(true);
      const { date, data: slots } = data;
      setCachedTime(date);
      if (Array.isArray(slots)) {
        setGroupedSlots(processCalendarData(slots));
      }
    } catch (error) {
      showUIToast({
        type: 'error',
        text: 'Error refreshing Calendly events. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReload = (): void => {
    void reload();
  };

  return (
    <div className="max-w-7xl mx-auto py-8">
      <Header cachedTime={cachedTime} handleReload={handleReload} />
      {loading ? (
        <div className="flex justify-center items-center max-w-5xl mx-auto p-6">
          <UILoader message={'Loading call sessions, please wait...'} />
        </div>
      ) : (
        <RenderCalendar groupedSlots={groupedSlots} />
      )}
    </div>
  );
};

export default CalendarComponent;
