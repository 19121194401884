import React, { type FC, useEffect, useState } from 'react';
import AccountViewmodel from '@/src-v2/Presentation/Views/Settings/Account/Account.viewmodel';
import SettingsTitleDivider from '@/src-v2/Presentation/Views/Settings/Components/SettingsTitleDivider.component';
import SettingsSection from '@/src-v2/Presentation/Views/Settings/Components/Layouts/SettingsSection.component';
import { Button } from '@components/ui/button';
import SettingsSectionOption from '@/src-v2/Presentation/Views/Settings/Components/Layouts/SettingsSectionOption.component';
import { UserAvatarImage } from '@/src-v2/Presentation/Views/Settings/Account/Components/UserAvatarImage.component';
import { Label } from '@components/ui/label';
import { Input } from '@components/ui/input';
import {
  Pencil1Icon,
  ResetIcon,
  ImageIcon,
  CheckIcon,
  IdCardIcon,
  EnvelopeClosedIcon,
  InfoCircledIcon,
} from '@radix-ui/react-icons';
import { Alert, AlertDescription, AlertTitle } from '@components/ui/alert';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@components/ui/select';
import { availableTimezones } from '@domain/enums/Users/timezone';
import { ChangePasswordComponent } from '@views/Settings/Account/Components/ChangePassword.component';
import UICardComponent from '../../Layout/Components/UICard.component';

const AccountView: FC = () => {
  const {
    toggleEditView,
    cancelEdit,
    saveChanges,
    chooseFile,
    removeImage,
    handleUserNameChange,
    handleUserTimezoneChange,
    viewState: { isEditing, hasImage, image, userName, email, currentTimezone },
  } = AccountViewmodel();

  const [showPopup, setShowPopup] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const callStatus = localStorage.getItem('callStatus');
    if (callStatus === 'paused') {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, []);

  return (
    <UICardComponent title="Account Settings" width="full" height="[100%]" minHeight="[400px]" showTitle={false}>
      <div id="ButtonContainer" className="flex gap-8 align-middle justify-end place-self-end">
        {!isEditing ? (
          <div
            className="relative"
            onMouseEnter={() => {
              if (isButtonDisabled) setShowPopup(true);
            }}
            onMouseLeave={() => {
              setShowPopup(false);
            }}
          >
            <Button
              className="gap-1.5"
              variant="outline"
              type="submit"
              onClick={toggleEditView}
              data-qa-id="edit-Account-Settings"
              disabled={isButtonDisabled}
            >
              <Pencil1Icon className="mr-2 h-4 w-4" />
              Edit Account Settings
            </Button>
            {showPopup && (
              <div className="absolute top-10 right-10 bg-white rounded-md p-2 shadow-md">
                You can not change account settings during a session
              </div>
            )}
          </div>
        ) : (
          <div className="flex w-[32%] gap-8 align-middle justify-end place-self-end">
            <Button variant="destructive" type="submit" onClick={cancelEdit} data-qa-id="cancelEditAccount">
              <ResetIcon className="mr-2 h-4 w-4" /> Cancel
            </Button>

            <Button
              className="bg-app-blue-light"
              variant="default"
              type="submit"
              formTarget="avatarInput"
              onClick={saveChanges}
              data-qa-id="saveAccountChanges"
            >
              <CheckIcon className="mr-2 h-4 w-4" />
              Save Changes
            </Button>
          </div>
        )}
      </div>

      <SettingsTitleDivider title="Account Settings" description="Manage Settings for your Tendril Connect account" />
      <SettingsSection name="Account Details">
        <SettingsSectionOption
          subtitle="Profile Picture"
          details="Supported formats are: .jpeg, .jpg, .png, .svg, .bmp, .tiff"
          actions={
            <>
              {isEditing && (
                <>
                  {hasImage ? (
                    <Button onClick={removeImage} variant="secondary" type={'submit'} data-qa-id={'removeProfilePic'}>
                      Remove
                    </Button>
                  ) : null}
                  <Button
                    className="relative bg-app-blue-light"
                    variant="default"
                    type={'submit'}
                    data-qa-id={'changeProfilePic'}
                  >
                    <Label htmlFor="avatarInput" className="absolute w-full h-full"></Label>
                    <ImageIcon className="mr-2 h-4 w-4" />
                    Change picture
                  </Button>
                  <Input
                    onChange={(e) => {
                      chooseFile(e);
                    }}
                    id="avatarInput"
                    className="hidden"
                    type={'file'}
                    accept={'.jpeg, .jpg, .png, .svg, .bmp, .tiff'}
                    data-qa-id={'chooseProfilePic'}
                  />
                </>
              )}
            </>
          }
        />
        <UserAvatarImage
          src={image}
          twClass="object-fill rounded-full md:w-32 md:h-32 xl:h-36 xl:w-36 relative padding-auto"
        />
        <SettingsSectionOption subtitle="Display name" details="Your display name is your Tendril Connect identity" />
        <div className="flex min-[1280px]:w-[60%]  items-center relative">
          <IdCardIcon className="absolute ml-4"></IdCardIcon>
          <Input
            type="text"
            placeholder={userName}
            readOnly={!isEditing}
            contentEditable={isEditing}
            className="w-full pl-12"
            data-qa-id="userNameInput"
            onInput={handleUserNameChange}
          />
        </div>
        <SettingsSectionOption subtitle="Account Email" details="Email linked to your Tendril Connect account" />
        {isEditing && (
          <>
            <Alert className="w-fit bg-sky-100 border-dashed border-app-blue-light">
              <InfoCircledIcon className="h-4 w-4" />
              <AlertTitle>Email Information</AlertTitle>
              <AlertDescription>Your registered email account cannot be changed for security reasons.</AlertDescription>
            </Alert>
          </>
        )}
        <div className="flex min-[1280px]:w-[60%] items-center relative">
          <EnvelopeClosedIcon className="absolute ml-4"></EnvelopeClosedIcon>
          <Input type="email" readOnly value={email} className="w-full pl-12 flex-shrink-0" />
        </div>
      </SettingsSection>
      <SettingsTitleDivider
        title="Region"
        description="Allows your account to be synchronized with your preferred location"
      />
      <SettingsSection name="Country or Region">
        <SettingsSectionOption
          subtitle="Preferred timezone"
          details="Helps you to display Tendril Connect content in your current local time"
        />
        <div className="flex min-[1280px]:w-[60%] items-center relative">
          <Select onValueChange={handleUserTimezoneChange}>
            <SelectTrigger disabled={!isEditing}>
              <SelectValue placeholder={currentTimezone as string} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {availableTimezones.map((timezone) => {
                  return (
                    <SelectItem key={timezone} data-qa-id={`option-${timezone}`} value={timezone}>
                      {timezone}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </SettingsSection>
      <SettingsTitleDivider
        title="Password and authentication"
        description="Protect your tendril account with strong password and secure authentication methods"
      />
      <SettingsSection name="Change password">
        {isEditing && (
          <div className={'flex flex-col'}>
            <Alert className="w-fit bg-sky-100 border-dashed border-app-blue-light">
              <InfoCircledIcon className="h-4 w-4" />
              <AlertDescription>
                To change your password, you must first verify your identity by current password
              </AlertDescription>
            </Alert>
          </div>
        )}
        {isEditing && (
          <div className={'flex flex-col'}>
            <ChangePasswordComponent />
          </div>
        )}
        {isEditing && <div className={'flex min-[300px]:w-[40%] flex-col items-center justify-center'}></div>}
        <div className="flex min-[1280px]:w-[60%] items-center relative"></div>
      </SettingsSection>
    </UICardComponent>
  );
};

export default AccountView;
