import React, { type FC, useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@components/ui/table';
import ContactInfoSheetComponent from '@views/Dashboard/Components/ContactInfoSheet.component';
import LayoutView from '@views/Layout/Layout.view';
import UICardComponent from '@views/Layout/Components/UICard.component';
import { Button } from '@components/ui/button';
import { type NavigateFunction, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DispositionDefaultAction } from '@views/types/disposition-mapping.type';
import { DashboardDetailsViewModel } from '@views/Dashboard/Components/DashboardDetails.viewmodel';
import moment from 'moment-timezone';
import { DashboardViewmodel } from '@views/Dashboard/Dashboard.viewmodel';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import { PrettyMetricActions } from '@views/types/metric-action.type';
import UILoader from '@/src-v2/Presentation/components/shared/UILoader';
import { featuresStore } from '@/src/components/app/FeaturesProvider';
import PaginatorControlsComponent from '@/src-v2/Presentation/components/shared/PaginatorControls.component';
import useScreenSize from '@/src/core/hooks/useScreenSize';
import RecordingPlayerComponent from '@views/Dashboard/Components/RecordingPlayer.component';
import { PrivateRoutes } from '@/src/constants/routesConnect';
import { ChevronRight } from 'lucide-react';
import { IconClipboardList, IconHeadset } from '@tabler/icons-react';
import tabTitle from '@/src/utils/updateTitle';

export function returnToDashboard(navigate: NavigateFunction): void {
  const lastPage = localStorage.getItem('lastDashboardPage') ?? '1';
  const lastSearch = localStorage.getItem('lastDashboardSearch') ?? '';

  navigate(`/${PrivateRoutes.DASHBOARD_V2}?search=${lastSearch}&page=${lastPage}`, { replace: true });
}

const DashboardDetailsView: FC = () => {
  const {
    callSessionData,
    contactInformation,
    loading,
    sdrAudio,
    agentAudio,
    sdrAudioDuration,
    agentAudioDuration,
    openedModal,
    setOpenedRecordingModal,
    getContactInformation,
    getDetailsInfo,
    getRecordings,
    setSdrAudio,
    setAgentAudio,
    paginationData,
  } = DashboardDetailsViewModel();

  const {
    featuresState: { callRecordings },
  } = useContext(featuresStore);
  const [pageNumber, setPageNumber] = useState(1);
  const [buttonsState, setButtonsState] = useState<Record<string, boolean>>({});
  const { timezone } = DashboardViewmodel();
  const { callSessionId: sessionId } = useParams();
  const cellStyle = 'text-center text-wrap';
  const screenSize = useScreenSize();
  const proportionConstant: number = Math.ceil(screenSize.height - screenSize.height / 2.5);
  const {
    userTypeAdmin,
    userSession: { isSuperAdmin },
  } = useOutletContext<OutletContextProps>();
  const navigate = useNavigate();

  const recordings = Boolean(callRecordings) && Boolean(userTypeAdmin);

  tabTitle('Tendril Connect | CS Details');

  function displayDispositionByAbbreviation(abbreviation: string): string {
    if (abbreviation === 'default_action') {
      return 'Default Action';
    }
    if (abbreviation in DispositionDefaultAction) {
      return DispositionDefaultAction[abbreviation as keyof typeof DispositionDefaultAction];
    }

    return 'Default Action';
  }

  function displayPrettyAction(rawAction: string): string {
    if (rawAction in PrettyMetricActions) {
      return PrettyMetricActions[rawAction as keyof typeof PrettyMetricActions];
    }
    return 'Default Action';
  }

  const handlePrev = (): void => {
    setPageNumber((last) => last - 1);
    getDetailsInfo(String(sessionId), pageNumber);
  };

  const handleNext = (): void => {
    setPageNumber((last) => last + 1);
    getDetailsInfo(String(sessionId), pageNumber);
  };

  useEffect(() => {
    getDetailsInfo(String(sessionId), pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    const updatedButtonsState: Record<string, boolean> = {};
    callSessionData.calls.map((call) => {
      updatedButtonsState[call.callId] = Boolean(call.existRecordings);
      return buttonsState;
    });

    setButtonsState(updatedButtonsState);
  }, [callSessionData]);

  const {
    session: { sdrName, sdrId, callSessionId, organizationName, organizationId },
    calls,
  } = callSessionData;

  const actionColors: Record<string, string> = {
    // Consult the colors on ~/tailwind.config.ts
    transferred: 'bg-app-disposition_bg-transferred text-app-disposition_text-transferred',
    transfer_error: 'bg-app-disposition_bg-transfer_error text-app-disposition_text-transfer_error',
    answered: 'bg-app-disposition_bg-answered text-app-disposition_text-answered',
    voicemail: 'bg-app-disposition_bg-voicemail text-app-disposition_text-voicemail',
    call_ended: 'bg-app-disposition_bg-call_ended text-app-disposition_text-call_ended',
    not_answered: 'bg-app-disposition_bg-not_answered text-app-disposition_text-not_answered',
    cut_off: 'bg-app-disposition_bg-cut_off text-app-disposition_text-cut_off',
    default: 'bg-app-disposition_bg-default text-app-disposition_text-default',
  };

  const getActionColor = (action: string): string => {
    return actionColors[action];
  };

  return (
    <LayoutView>
      <nav className="flex items-center space-x-1 text-lg text-muted-foreground">
        {/* Dashboard Breadcrumb */}
        <button
          type="button"
          className="hover:text-white text-blue-700"
          onClick={() => {
            returnToDashboard(navigate);
          }}
        >
          Dashboard
        </button>
        <ChevronRight className="h-4 w-4 text-blue-700" />
        {/* Summary Breadcrumb */}
        <button
          type="button"
          className="hover:text-white text-blue-700"
          onClick={() => {
            navigate(`/${PrivateRoutes.SUMMARY_V2}/${sessionId as string}`);
          }}
        >
          Summary
        </button>
        <ChevronRight className="h-4 w-4 text-blue-700" />
        {/* Current Page */}
        <span className="text-white font-extrabold">Session Details</span>
      </nav>

      <h1 className="mx-3 my-4 font-extrabold text-3xl text-white">Call Session Details</h1>

      <header className="flex items-center w-full">
        <div className="grid grid-cols-4 gap-4 p-4 rounded bg-white w-full">
          {/* SDR Card */}
          <HeaderCard title="SDR" content={loading ? 'Loading...' : sdrName} subtitle={sdrId} />
          {/* Call Session Id Card */}
          <HeaderCard title="Call Session Id" content={loading ? 'Loading...' : callSessionId} />
          {/* Organization Card */}
          <HeaderCard
            title="Organization"
            content={loading ? 'Loading...' : organizationName}
            subtitle={organizationId}
          />
          {/* Contact List Card */}
          <HeaderCard
            title="Contact List"
            content={loading ? 'Loading...' : calls?.[0]?.contactListName ?? 'Unavailable'}
            subtitle={calls[0]?.contactListId}
          />
        </div>
      </header>

      <UICardComponent title="Dashboard Details" showTitle={false} width="[full]" height="[100%]">
        {loading ? (
          <div>
            <UILoader message={'Loading session details, please wait...'} size="4/5" />
          </div>
        ) : (
          <div
            style={{ height: screenSize.height > 700 ? `${proportionConstant}px` : '100%' }}
            className={`${
              screenSize.height < 700 ? 'max-h-[430px] min-[1500px]:max-h-[430px] min-[1024px]:max-h-[375px]' : ''
            } overflow-y-scroll`}
          >
            <Table>
              <TableHeader className={`${cellStyle} bg-secondary`}>
                <TableCell>Contact</TableCell>
                {isSuperAdmin && <TableCell>Agent</TableCell>}
                <TableCell>Timestamp</TableCell>
                <TableCell>Disposition</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Notes</TableCell>
                {recordings && <TableCell>Recordings</TableCell>}
              </TableHeader>
              <TableBody>
                {calls.map((callSession, index) => {
                  const {
                    agentId,
                    agentName,
                    action,
                    disposition,
                    contactId,
                    contactName,
                    callId,
                    createdAt: callTimestamp,
                  } = callSession;

                  const buttonState = buttonsState[callId];

                  return (
                    <TableRow key={index} className={'items-center'}>
                      <TableCell className={`${cellStyle} min-w-40 min-[1400px]:w-72`}>
                        <ContactInfoSheetComponent
                          contact={contactInformation}
                          triggerElement={
                            <a
                              className="underline text-app-blue-light"
                              onClick={() => {
                                getContactInformation(contactId);
                              }}
                            >
                              {contactName}
                            </a>
                          }
                          triggerButtonStyles="min-w-40 min-[1100px]:w-72 cursor-pointer"
                        />
                      </TableCell>
                      {isSuperAdmin && (
                        <TableCell className={cellStyle}>
                          {agentName}
                          <br />
                          <h3 className="text-app-gray-300">{agentId}</h3>
                        </TableCell>
                      )}
                      <TableCell className={cellStyle}>{moment(callTimestamp).tz(timezone).format('llll')}</TableCell>
                      <TableCell className={cellStyle}>{displayDispositionByAbbreviation(disposition)}</TableCell>
                      <TableCell className={`${cellStyle}`}>
                        <div className={`px-0.5 py-1 rounded ${getActionColor(action)}`}>
                          {displayPrettyAction(action)}
                        </div>
                      </TableCell>
                      <TableCell className={`${cellStyle}`}>
                        <ContactInfoSheetComponent
                          contact={contactInformation}
                          triggerElement={
                            <IconClipboardList
                              color="#0095D4"
                              onClick={() => {
                                getContactInformation(contactId);
                              }}
                            />
                          }
                          triggerButtonStyles="flex justify-center max-w-12 cursor-pointer"
                        />
                      </TableCell>
                      {recordings && (
                        <TableCell className={`${cellStyle}`}>
                          <RecordingPlayerComponent
                            opened={openedModal}
                            setOpenedModal={setOpenedRecordingModal}
                            sdrAudio={sdrAudio}
                            sdrAudioDuration={sdrAudioDuration}
                            agentAudio={agentAudio}
                            agentAudioDuration={agentAudioDuration}
                            setSdrAudio={setSdrAudio}
                            setAgentAudio={setAgentAudio}
                            trigger={
                              <Button
                                id={callId}
                                className={'border-0'}
                                size={'icon'}
                                variant={'outline'}
                                disabled={!buttonState}
                              >
                                <IconHeadset
                                  color="#0095D4"
                                  onClick={() => {
                                    void getRecordings(callId);
                                  }}
                                />
                              </Button>
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
        {!loading && (
          <div className="flex min-w-full justify-end">
            <PaginatorControlsComponent
              pageNumber={paginationData.pageNumber}
              totalPages={paginationData.totalPages}
              totalCount={paginationData.totalCount}
              pageSize={paginationData.pageSize}
              onPrevPage={handlePrev}
              onNextPage={handleNext}
            />
          </div>
        )}
      </UICardComponent>
    </LayoutView>
  );
};

interface HeaderCardProps {
  title: string;
  content: string | number | undefined;
  subtitle?: string | number | undefined;
}

const HeaderCard: React.FC<HeaderCardProps> = ({ title, content, subtitle }) => {
  return (
    <div className="flex flex-col justify-center text-center border border-gray-400 rounded bg-white text-gray-600 font-bold px-4 py-2">
      <h4 className="text-gray-800 font-bold text-sm">{title}</h4>
      <h5 className="text-gray-600 text-xs">{content}</h5>
      {subtitle !== undefined && <h6 className="text-gray-500 text-xs">{subtitle}</h6>}
    </div>
  );
};

export default DashboardDetailsView;
