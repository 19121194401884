import { type ApiResponse } from '@/src/types/ApiResponse';
import { type BuiltDispositions, type BuiltMetric } from '@datasource/contacts-nest/metrics/entities/metrics.entity';
import getBuiltMetricsAsync from '@datasource/contacts-nest/metrics/getBuiltMetrics';
import getBuiltDispositions from '@datasource/contacts-nest/metrics/getBuiltDispositions';

interface MetricsRepositoryProps {
  getBuiltMetrics: (callSessionId: string) => Promise<ApiResponse<BuiltMetric>>;
  getBuiltDispositions: (callSessionId: string) => Promise<ApiResponse<BuiltDispositions>>;
}
export function MetricsRepository(): MetricsRepositoryProps {
  return {
    async getBuiltMetrics(callSessionId: string): Promise<ApiResponse<BuiltMetric>> {
      const { data, success, error } = await getBuiltMetricsAsync(callSessionId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getBuiltDispositions(callSessionId: string): Promise<ApiResponse<BuiltDispositions>> {
      const { data, success, error } = await getBuiltDispositions(callSessionId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}
