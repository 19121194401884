import React, { useEffect, useState } from "react";
import { CallSession } from "../shared/CallSession";
import {
  HomepageColumn,
  HomepageMessageCircle,
  HompageBox,
  HomepageIconTitle,
  HomepageTable,
} from "../shared/HomepageElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { showUIToast } from "../../core/ui/UIToast";
import getPreviewAsync from "../../api/CallSession/getPreviewAsync";
import { RetryTimer } from "../../core/hooks/useRetryTimer";
import getCurrentNumberUser from "../../api/CallerId/getCurrentNumberUser";
import UILoader from "../../core/ui/UILoader";
import styled from "styled-components";
type Props = {
  user: string;
  callSession: CallSession | null;
  hasCallSession: boolean;
  onStart: () => void;
  retryTimer: RetryTimer;
  children: React.Node;
};

const ReadyContainer = ({
  onStart,
  user,
  callSession,
  hasCallSession,
  retryTimer,
  children,
}: Props, ): JSX.Element => {
  const userSession = JSON.parse(window.localStorage.getItem("user") || "{}");
  const [callSessionData, setCallSessionData] = useState<CallSession>();
  const [currentNumber, setCurrentNumber] = useState("");
  const { retryDelay, isWaitingRetry } = retryTimer;
  const [loadingStatus, setLoadingStatus] = useState(true);

  const StartContainer = styled.div`
    display: grid;
    gap: 4em;
    justify-items: center;
  `;

  useEffect(() => {
    const fetchReviewCallSession = async () => {
      const { previewCallSession, error } = await getPreviewAsync({
        sdrId: userSession.userId,
      });

      if (error) {
        showUIToast({
          type: "error",
          text: "Error getting call session preview, try again later.",
        });
      }

      if (previewCallSession) {
        setCallSessionData(previewCallSession);
      }
    };

    const fetchNumber = async () => {
      const { currentNumber: number, error: errorNumber } =
        await getCurrentNumberUser();

      if (errorNumber || !number) {
        showUIToast({
          type: "error",
          text: "Error getting call session preview, try again later.",
        });
      } else {
        setCurrentNumber(number);
      }
    };

    fetchNumber();

    if (callSession?.contactListName) {
      setCallSessionData(callSession);

      setLoadingStatus(!loadingStatus);
    } else {
      fetchReviewCallSession().then(() => {
        setLoadingStatus(!loadingStatus);
      });
    }
  }, []);

  const tableData = {
    tableHeaders: ["Name", "Company", "Position", "Phone Number"],
    data: callSessionData?.previewContacts.map((obj) => {
      if (callSessionData)
        return ["name", "accountName", "jobTitle", "phone"].map((key) => {
          if (obj.hasOwnProperty(key)) return obj[key];
          return "";
        });
    }),
  };

  return (
    <>
    <StartContainer>
      <HomepageMessageCircle
        message={
          hasCallSession
            ? `${user}, your session was paused. Click the button to resume it.`
            : `${user}, Are you ready?`
        }
        buttonMessage={
          isWaitingRetry ? `Retry in ${retryDelay} sec.` : `Let's start`
        }
        handleClick={onStart}
      />
      {children}
      </ StartContainer>
      {loadingStatus ? (
        <UILoader message="Getting preview call session..." />
      ) : (
        callSessionData && (
          <HomepageColumn gap="2rem" width="500px">
            <HompageBox>
              <HomepageIconTitle>
                <FA icon={["fas", "address-book"]} />
                Contact List:
              </HomepageIconTitle>
              <ul className="list">
                {callSessionData?.contactListName.map((name, i) => (
                  <li key={i}>{name}</li>
                ))}
              </ul>
              <div className="right">
                Total contacts: {callSessionData?.totalContacts}
              </div>
            </HompageBox>

            <HomepageTable
              tableData={tableData}
              {...(callSessionData?.previewContacts.length > 6 && {
                maxRows: 6,
              })}
            />

            <HompageBox>
              <HomepageIconTitle>
                <FA icon={["fas", "volume-high"]} />
                Voice Mail:
              </HomepageIconTitle>
              <ul className="list">
                {callSessionData?.voiceDropsTitles.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </HompageBox>

            <HompageBox>
              <HomepageIconTitle>
                <FA icon={["fas", "comments-dollar"]} />
                Intent:
              </HomepageIconTitle>
              <ul className="list">
                <li>{callSessionData?.scriptTitle}</li>
              </ul>
            </HompageBox>

            <HompageBox>
              <HomepageIconTitle>
                <FA icon={["fas", "phone-alt"]} />
                Number:
              </HomepageIconTitle>
              <ul className="list">
                <li>{currentNumber}</li>
              </ul>
            </HompageBox>
          </HomepageColumn>
        )
      )}
    </>
  );
};

export default ReadyContainer;
