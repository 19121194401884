import { MetricsRepository } from '@repository/contacts-nest/metrics/metricsRepository';
import { CallSessionRepository } from '@repository/contacts-nest/callSession/callSessionRepository';
import { UsersRepository } from '@repository/edge/users/usersRepository';
import { ContactsRepository } from '@repository/contacts-nest/contacts/contactsRepository';
import OrganizationsRepository from '@repository/edge/organizations/organizationsRepository';
import type { ApiResponse } from '@/src/types/ApiResponse';
import type { BuiltDispositions, BuiltMetric } from '@datasource/contacts-nest/metrics/entities/metrics.entity';
import type { CallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import type { SafeUserDto } from '@datasource/edge/users/dto/safeUser.dto';
import type { ContactInfo } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import type { ActiveOrganizations } from '@datasource/edge/organizations/organizations';

interface SummaryUseCaseProps {
  getBuiltMetrics: (callSessionId: string) => Promise<ApiResponse<BuiltMetric>>;

  getBuiltDispositions: (callSessionId: string) => Promise<ApiResponse<BuiltDispositions>>;

  getCallSession: (callSessionId: string) => Promise<ApiResponse<CallSession>>;

  getUserAsync: (userId: string) => Promise<ApiResponse<SafeUserDto>>;

  getContactListData: (contactListId: string) => Promise<ApiResponse<ContactInfo[]>>;

  getOrganization: (organizationId: string) => Promise<ApiResponse<ActiveOrganizations>>;
}

function SummaryUseCase(): SummaryUseCaseProps {
  const { getBuiltMetrics, getBuiltDispositions } = MetricsRepository();
  const { getCallSessionById } = CallSessionRepository();
  const { getUserAsync } = UsersRepository();
  const { getContactsByContactListId } = ContactsRepository();
  const { getOrganizationById } = OrganizationsRepository();

  return {
    async getBuiltMetrics(callSessionId: string) {
      const { data, error, success, status } = await getBuiltMetrics(callSessionId);
      return { data, error, success, status };
    },

    async getBuiltDispositions(callSessionId: string) {
      const { data, error, success, status } = await getBuiltDispositions(callSessionId);
      return { data, error, success, status };
    },

    async getCallSession(callSessionId: string) {
      const { data, error, success, status } = await getCallSessionById(callSessionId);
      return { data, error, success, status };
    },

    async getUserAsync(userId: string) {
      const { data, error, success, status } = await getUserAsync(userId);
      return { data, error, success, status };
    },

    async getContactListData(contactListId: string) {
      const { data, error, success, status } = await getContactsByContactListId(contactListId);
      return { data, error, success, status };
    },

    async getOrganization(organizationId: string) {
      const { data, error, success, status } = await getOrganizationById(organizationId);
      return { data, error, success, status };
    },
  };
}

export default SummaryUseCase;
