import { type ApiResponse } from '@/src/types/ApiResponse';
import { type CalendarResponse } from '@datasource/edge/calendar/entities/calendar.entity';
import getCalendlySession from '@datasource/edge/calendar/getCalendlySession';

interface CalendarRepositoryProps {
  getCalendlySessions: (userId: string, cache: boolean) => Promise<ApiResponse<CalendarResponse>>;
}

function CalendarRepository(): CalendarRepositoryProps {
  return {
    async getCalendlySessions(userId, cache): Promise<ApiResponse<CalendarResponse>> {
      const { data, success, error } = await getCalendlySession(userId, cache);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

export default CalendarRepository;
