import React, { createContext, useContext, useEffect, useState } from 'react';
import { config } from '../../config';
import { AuthUseCase } from '@usecase/auth/AuthUseCase';
import { UserProviderContext } from "../../components/app/UserProvider";
import { ILogger,LogtailLoggerService, Context } from "@/src-v2/Shared/logger/logger.service";
import checkConnectionPerformance from "@/src/utils/checkConnectionPerformance";

type LoggerContextType = {
  logger: ILogger;
  logWithToken?: (token: string, message:string, context: Context) => void;
};

export const LoggerContext = createContext<LoggerContextType>({ logger: new LogtailLoggerService() });

export const useLogger = () => useContext(LoggerContext);

export const LoggerProvider: React.FC<{ children: React.ReactElement; }> = ({ children }) => {
  const [logger, setLogger] = useState<ILogger>(new LogtailLoggerService());
  const { currentUser } = useContext(UserProviderContext);

  const logWithToken = async (token: string, message: string, context: Context) => {
    if (config.sentry.environment !== 'local') {
      logger.setInstance(token);
      logger.info(message, context);
    }
  };

  useEffect(() => {
    if (config.sentry.environment !== 'local' && Object.keys(currentUser).length) {
      const initializeLogger = async () => {
        try {
          const { data } = await AuthUseCase().getLoggerTokenAsync();

          logger.setInstance(data as string);
        } catch (error) {
          console.error("Error initializing Logtail:", error);
        }
      };

      initializeLogger();
    }
  }, [currentUser, logger]);
checkConnectionPerformance(logger);
  return (
    <LoggerContext.Provider value={{ logger, logWithToken }}>
      {children}
    </LoggerContext.Provider>
  );
};
