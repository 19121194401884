import routes from "../../constants/routes";
import axios from "axios";
import { SalesforceFolder } from "../../types/SalesforceTypes";

type FoldersProps = {
    lists?: SalesforceFolder[];
    error?: { message: string };
};

const getFoldersSalesforceAsync = async (
    userId: string,
    orgId: string,
    search?: string
): Promise<FoldersProps> => {
    try {
        let url = `${routes.get.salesforce.getFolders}/${orgId}/${userId}`;
        
        if (search) {
            url += `?search=${encodeURIComponent(search)}`;
        }

        const salesforce = await axios.get<SalesforceFolder[]>(url);

        return { lists: salesforce?.data };
    } catch (error) {
        return { error: { message: "Error while getting sequences" } };
    }
};

export default getFoldersSalesforceAsync;
