/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect, useContext, type ReactElement } from 'react';
import DeleteAccountModal from '@/src/pages/Settings/Shared/Components/DeleteAccountModal';
import IntegrationUser from '@/src/pages/Settings/Shared/Components/integrations/IntegrationUser.component';
import salesforceLogo from '@/src/assets/icons/salesforce.svg';
import Loader from '@/src/core/ui/UILoader';
import styled from 'styled-components';
import { UserProviderContext } from '@/src/components/app/UserProvider';
import { type Account, AccountType } from '@/src/pages/Settings/Shared/Entities/Account';
import { BaseField, BaseLabel } from '@/src/core/ui/base/FormElements';
import { UIButton, UIMiniButton } from '@/src/core/ui/UIElements';
import { type SalesforceApp } from '@/src/types/SalesforceTypes';
import { Flex } from '@/src/core/ui';
import routes from '@/src/constants/routes';
import axios from 'axios';
import saveSalesforceConfigAsync from '@/src/api/Sync/saveSalesforceConfigAsync';
import getSalesforceConfigAsync from '@/src/api/Sync/getSalesforceConfigAsync';
import deleteSalesforceAccountAsync from '@/src/api/Sync/deteleSalesforceAccountAsync';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@components/ui/collapsible';
import { Button } from '@components/ui/button';
import { Input } from '@components/ui/input';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTriangleExclamation, faPlus, faGear, faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from '@components/ui/use-toast';
import UICardComponent from '@views/Layout/Components/UICard.component';

const SalesforceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: 395px;
`;

const IntegrationsList = styled.div<{
  scrollStartingAt: number;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  ${({ scrollStartingAt }) =>
    scrollStartingAt > 0 &&
    `
    max-height: calc(82px * ${scrollStartingAt} + (.5rem * ${scrollStartingAt}));
    overflow-y: scroll;
  `}
`;

export const SalesForceView = (): ReactElement => {
  const { currentUser } = useContext(UserProviderContext);
  const [sandboxIsChecked, setSandboxIsChecked] = useState<boolean>(false);
  const [salesforceApp, setSalesforceApp] = useState<SalesforceApp>({
    id: '',
    appId: '',
    accessKey: '',
    accessSecret: '',
    url: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    organizationId: '',
    userId: '',
    callbackUrl: '',
    sandbox: false,
  });
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [state, dispatch] = useState<{
    ready: boolean;
    configuring: boolean;
    message: string;
    loading: boolean;
    loadingMessage: string;
    hasConfig: boolean;
  }>({
    ready: true,
    configuring: false,
    message: 'Use button above to connect Salesforce.',
    loading: true,
    loadingMessage: 'Loading linked accounts',
    hasConfig: false,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    nonce: '',
  });
  const [isOpen, setIsOpen] = React.useState(false);

  const formInputStyle = `relative flex rounded-md border-b px-4 text-sm shadow-b-sm w-[95%]`;
  const formHeaderCollapseStyles = 'rounded-md border-b py-2 text-sm shadow-b-sm font-semibold w-full';

  const logoImageUrl = 'https://static.wixstatic.com/media/464f86_fb6b1a9d5fb54dd591d01fde63729ebb~mv2.png';
  const iconUrl = 'https://static.wixstatic.com/media/464f86_0a670f2bdd8d4cb2bd123d450b8efa53~mv2.png';

  const copyToClipboard = (element: string, name: string): void => {
    void navigator.clipboard
      .writeText(element)
      .then(() => {
        toast({
          variant: 'default',
          title: 'Copied',
          description: `Copied ${name} to clipboard successfully`,
        });
      })
      .catch(() => {
        toast({
          variant: 'destructive',
          title: 'Fail',
          description: 'Could not copy to clipboard',
        });
      });
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const { salesforceConfig } = await getSalesforceConfigAsync(currentUser.userId, currentUser.organizationId);
        if (salesforceConfig != null) {
          setSalesforceApp(salesforceConfig);
          dispatch((state) => ({
            ...state,
            hasConfig:
              salesforceConfig.appId !== '' &&
              salesforceConfig.accessKey !== '' &&
              salesforceConfig.accessSecret !== '',
          }));
        } else {
          toast({
            variant: 'destructive',
            title: 'Could not get salesforce data.',
            description: 'An error occurred while getting Salesforce configuration, please try later.',
          });
          dispatch((state) => ({ ...state, hasConfig: false }));
        }
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'An error occurred while getting Salesforce configuration, please try later.',
        });

        dispatch((state) => ({ ...state, hasConfig: false }));
        console.error(error);
      }
    };
    void fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (accounts.length === 0) {
      changeMessage('No accounts linked.');
    } else {
      changeMessage(`You have ${accounts.length} ${accounts.length === 1 ? 'account' : 'accounts'} linked.`);
    }
  }, [accounts.length]);

  const handleConnect = async (): Promise<void> => {
    try {
      const url = `${routes.post.salesforce.oAuthLogin}/${currentUser.organizationId}/${currentUser.userId}`.concat(
        `?sandbox=${sandboxIsChecked}`,
      );

      const res = await axios.post<{ url: string }>(url);
      if (res.status === 201) {
        window.open(res.data.url, 'salesforcePopup', `width=${500},height=${500}`);
        getAccountsInterval();
        toast({ variant: 'default', title: 'Success!', description: 'Salesforce connected' });

        dispatch((state) => ({
          ...state,
          configuring: true,
        }));
        return;
      }
    } catch (err) {
      salesforceError();
    }
  };

  const salesforceError = (): void => {
    toast({
      variant: 'destructive',
      title: 'Error',
      description: "Can't configure Salesforce right now, please try again later.",
    });
    dispatch((state) => ({
      ...state,
      ready: false,
      message: "Can't configure Salesforce right now, please try again later.",
    }));
  };

  const changeMessage = (message: string): void => {
    dispatch((state) => ({ ...state, message }));
  };

  const handleCancel = (): void => {
    dispatch((state) => ({
      ...state,
      configuring: false,
      message: 'Use button above to connect Salesforce.',
    }));
  };

  const getAccounts = async (wipeInterval?: () => void): Promise<void> => {
    dispatch((state) => ({
      ...state,
      loading: false,
    }));
    try {
      setAccounts([]);
      const listenUrl = `${routes.get.salesforce.getLinkedAccounts}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.get<Account[]>(listenUrl);
      if (res.status === 202) {
        toast({
          variant: 'default',
          title: 'info',
          description: 'Salesforce syncing in progress.',
        });
        dispatch((state) => ({
          ...state,
          configuring: true,
          message: 'Configuration in process.',
        }));
        return;
      }
      if (res.status === 204) {
        if (wipeInterval != null) wipeInterval();
        dispatch((state) => ({
          ...state,
          configuring: false,
        }));
        toast({
          variant: 'default',
          title: 'Info',
          description: 'Salesforce synced.',
        });
        changeMessage('Nothing was configured.');
        return;
      }
      if (res.status === 200) {
        if (wipeInterval != null) wipeInterval();
        const amount = res.data.length > 0 ? res.data.length : 0;
        changeMessage(`You have ${amount} ${amount > 1 ? 'accounts' : 'account'} linked.`);
        dispatch((state) => ({
          ...state,
          loading: false,
          configuring: false,
        }));
        toast({
          variant: 'default',
          title: 'Info',
          description: `Salesforce Custom linked with ${amount} ${amount > 1 ? 'accounts' : 'account'}.`,
        });
        setAccounts(res.data ?? []);
        return;
      }
    } catch (err) {
      if (wipeInterval != null) wipeInterval();
      dispatch((state) => ({
        ...state,
        loading: false,
      }));
      console.log(err);
      changeMessage('No accounts linked.');
    }
  };

  const getAccountsInterval = (): void => {
    const interval = setInterval(() => {
      getAccounts(() => {
        clearInterval(interval);
      }).catch((error) => {
        console.error('Error while fetching accounts', error);
        clearInterval(interval);
      });
    }, 100);
  };

  const handleInputChange = (event: { target: { name: string; value: string } }): void => {
    setIsOpen(true);
    const { name, value } = event.target;
    setSalesforceApp({ ...salesforceApp, [name]: value });
  };

  const handleSandboxChange = (event: {
    target: {
      checked: boolean;
    };
  }): void => {
    setSandboxIsChecked(event.target.checked);
    setSalesforceApp({ ...salesforceApp, sandbox: event.target.checked });
  };

  const handleSave = async (): Promise<void> => {
    try {
      const { salesforceConfig } = await saveSalesforceConfigAsync(
        currentUser.organizationId,
        currentUser.userId,
        salesforceApp,
      );

      if (salesforceConfig != null) {
        const salesforceConfigPayload = { ...salesforceApp, ...salesforceConfig };

        setSalesforceApp(salesforceConfigPayload);

        toast({
          variant: 'default',
          title: 'Success',
          description: 'Salesforce configuration saved.',
        });
        dispatch((state) => ({ ...state, hasConfig: true }));
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Error saving Salesforce configuration, please try again later.',
        });
        dispatch((state) => ({
          ...state,
          hasConfig: false,
          message: 'An error occurred while saving the configuration',
        }));
      }
    } catch (error) {
      console.error('Error saving Salesforce config:', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'An unexpected error occurred while saving Salesforce configuration.',
      });
    }
  };

  const removeSalesforceUser = async (nonce: string): Promise<void> => {
    const { success, error } = await deleteSalesforceAccountAsync(
      currentUser.organizationId,
      currentUser.userId,
      nonce,
    );

    if (success !== '' || success !== undefined) {
      const newUsers = accounts.filter((elem) => elem.nonce !== (nonce != null ? nonce : ''));
      setAccounts(newUsers);
      toast({
        variant: 'default',
        title: 'Info',
        description: 'Salesforce account removed.',
      });
      setDeleteModal({ isOpen: false, nonce: '' });
    } else if (error?.message !== '' && error?.message != null) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Error removing Salesforce account.',
      });
    }
  };

  /* eslint-enable @typescript-eslint/strict-boolean-expressions */

  useEffect(() => {
    void getAccounts();
  }, [currentUser]);

  return (
    <UICardComponent title="Salesforce" width="full" height="[100%]" minHeight="[400px]" showTitle={false}>
      <SalesforceContainer>
        <DeleteAccountModal
          isOpen={deleteModal.isOpen}
          onClose={() => {
            setDeleteModal({ isOpen: false, nonce: '' });
          }}
          handleDelete={() => {
            removeSalesforceUser(deleteModal.nonce).catch((err) => {
              console.error('Error removing Salesforce user:', err);
            });
          }}
        />
        {state.loading ? (
          <Loader message={state.loadingMessage} />
        ) : (
          <>
            <BaseField>
              <BaseLabel>
                Add your Salesforce External App Config.{' '}
                <a
                  target="_blank"
                  href="https://help.salesforce.com/s/articleView?id=analytics.bi_app_create.htm&type=5"
                  rel="noreferrer"
                  className="text-app-blue-lighter underline"
                >
                  Learn how to set up developer accounts.
                </a>
              </BaseLabel>
            </BaseField>
            <Collapsible
              open={isOpen || salesforceApp.accessKey === '' || salesforceApp.accessSecret === ''}
              onOpenChange={setIsOpen}
              style={{ width: 'max(95%, 500px)' }}
              className={'grid space-y-2'}
            >
              <div className="flex items-center justify-between space-x-4 px-4 w-full">
                {salesforceApp.accessKey === '' || salesforceApp.accessSecret === '' ? (
                  <>
                    <FA icon={faPlus} size="lg" className="text-app-blue-light" />
                    <div className={formHeaderCollapseStyles}>Setup App Config</div>
                  </>
                ) : isOpen ? (
                  <>
                    <FA icon={faTriangleExclamation} size="lg" className="text-app-warning" />
                    <div className={formHeaderCollapseStyles}>Edit Config</div>
                  </>
                ) : (
                  <>
                    <FA icon={faCircleCheck} size="lg" className="text-app-ok" />
                    <div className={formHeaderCollapseStyles}>Your App Config is set</div>
                  </>
                )}

                <CollapsibleTrigger asChild>
                  <Button variant="ghost" size="lg">
                    <FA icon={faGear} size="xl" className="text-app-blue-light" />
                  </Button>
                </CollapsibleTrigger>
              </div>
              <CollapsibleContent className={`space-y-2 ${!isOpen ? '' : 'animate-table-soft-transition'}`}>
                <BaseField>
                  <BaseLabel title="External Client App Name">External Client App Name</BaseLabel>
                  <Input
                    type="text"
                    name="appId"
                    data-qa-id="appIdInput"
                    className={formInputStyle}
                    value={salesforceApp.appId !== undefined ? salesforceApp.appId : ''}
                    onChange={handleInputChange}
                  />
                </BaseField>
                <BaseField>
                  <BaseLabel title="Consumer Key">Consumer Key</BaseLabel>
                  <Input
                    type="text"
                    name="accessKey"
                    data-qa-id="accessKeyInput"
                    className={formInputStyle}
                    value={salesforceApp.accessKey !== undefined ? salesforceApp.accessKey : ''}
                    onChange={handleInputChange}
                  />
                </BaseField>
                <BaseField>
                  <BaseLabel title="Consumer Secret">Consumer Secret</BaseLabel>
                  <Input
                    className={formInputStyle}
                    type="text"
                    name="accessSecret"
                    data-qa-id="accessSecretInput"
                    value={salesforceApp.accessSecret !== undefined ? salesforceApp.accessSecret : ''}
                    onChange={handleInputChange}
                  />
                </BaseField>
                <BaseField>
                  <BaseLabel title="Redirect URL (OAuth)">Redirect URL (OAuth)</BaseLabel>
                  <Input
                    name="callbackUrl"
                    data-qa-id="callbackUrlInput"
                    type="text"
                    className={formInputStyle}
                    readOnly={true}
                    value={salesforceApp.callbackUrl !== '' ? salesforceApp.callbackUrl : ''}
                  />
                  <Button
                    variant="outline"
                    size="icon"
                    style={{ left: '97%', top: '45%' }}
                    className="absolute border-0 hover:bg-transparent"
                    onClick={() => {
                      copyToClipboard(salesforceApp.callbackUrl, 'Callback URL');
                    }}
                  >
                    <FA icon={faCopy} size="lg" />
                  </Button>
                </BaseField>
                <BaseField>
                  <BaseLabel title="Logo Image URL">Logo Image URL</BaseLabel>
                  <Input
                    name="logoImageURL"
                    data-qa-id="logoImageURLInput"
                    type="text"
                    className={formInputStyle}
                    readOnly={true}
                    value={logoImageUrl}
                  />
                  <Button
                    variant="outline"
                    size="icon"
                    style={{ left: '97%', top: '45%' }}
                    className="absolute border-0 hover:bg-transparent"
                    onClick={() => {
                      copyToClipboard(logoImageUrl, 'Logo Image URL');
                    }}
                  >
                    <FA icon={faCopy} size="lg" />
                  </Button>
                </BaseField>
                <BaseField>
                  <BaseLabel title="Icon URL">Icon URL</BaseLabel>
                  <Input
                    name="iconURL"
                    data-qa-id="iconURLInput"
                    type="text"
                    className={formInputStyle}
                    readOnly={true}
                    value={iconUrl}
                  />
                  <Button
                    variant="outline"
                    size="icon"
                    style={{ left: '97%', top: '45%' }}
                    className="absolute border-0 hover:bg-transparent"
                    onClick={() => {
                      copyToClipboard(iconUrl, 'Icon URL');
                    }}
                  >
                    <FA icon={faCopy} size="lg" />
                  </Button>
                </BaseField>
                <Flex id="formFooter" flexDirection="row" justifyContent="space-between" width="100%">
                  <div className="flex items-center space-x-2">
                    <Input
                      id="sandbox"
                      data-qa-id="sandboxInput"
                      type="checkbox"
                      className="peer h-4 w-4 shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
                      checked={salesforceApp.sandbox}
                      onChange={(e) => {
                        handleSandboxChange(e);
                      }}
                    />
                    <label
                      htmlFor="sandbox"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Sandbox
                    </label>
                  </div>
                  <UIButton
                    onClick={() => {
                      handleSave().catch((error) => {
                        console.error('Error in saving:', error);
                      });
                    }}
                  >
                    Save
                  </UIButton>
                </Flex>
              </CollapsibleContent>
              {state.hasConfig && (
                <>
                  <UIMiniButton
                    className="salesforce"
                    style={{
                      justifySelf: 'center',
                      backgroundColor: 'white',
                      padding: '65px',
                      backgroundImage: `url(${salesforceLogo})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                    disabled={state.configuring}
                    onClick={() => {
                      void handleConnect();
                    }}
                  ></UIMiniButton>
                  {state.configuring && <UIMiniButton onClick={handleCancel}>Cancel Configuration</UIMiniButton>}
                  <IntegrationsList scrollStartingAt={accounts.length > 10 ? 5 : 0}>
                    {accounts.map((user) => {
                      const { id, attributes, email, nonce } = user;
                      return (
                        <IntegrationUser
                          key={nonce}
                          remove={(nonce) => {
                            setDeleteModal({ isOpen: true, nonce });
                          }}
                          id={id}
                          type={AccountType.Salesforce}
                          email={email}
                          nonce={nonce}
                          {...attributes}
                        />
                      );
                    })}
                  </IntegrationsList>
                  <div>{state.message}</div>
                </>
              )}
            </Collapsible>
          </>
        )}
      </SalesforceContainer>
    </UICardComponent>
  );
};

export type { SalesforceApp };
