export enum FeatureFlagNames {
  Outreach = 'Outreach',
  Fourmativ = '4mativ',
  Hubspot = 'Hubspot',
  HubspotCustom = 'Hubspot Custom',
  UserSettings = 'User Settings',
  AccountSettings = 'Account Settings',
  NumbersPool = 'Numbers Pool',
  CallerId = 'Caller ID',
  ContactsFilter = 'Contacts Filter',
  DashboardDetails = 'Dashboard Details',
  CallRecordings = 'Call Recordings',
  Salesforce = 'Salesforce',
  Calendly = 'Calendly',
}
