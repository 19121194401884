import React from "react";
import { Card, Footer, CardsContainer } from "../shared";
import Notes from "../shared/Notes";
import { Text } from "../../core/ui";

const fakeNotes = [
  {
    id: "1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisiesunt in culpa.",
    author: "Miguel Castillo",
    date: "12 / 07 / 21",
  },
  {
    id: "2",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisiesunt in culpa.",
    author: "Miguel Castillo",
    date: "12 / 07 / 21",
  },
  {
    id: "3",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisiesunt in culpa.",
    author: "Miguel Castillo",
    date: "12 / 07 / 21",
  },
  {
    id: "4",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisiesunt in culpa.",
    author: "Miguel Castillo",
    date: "12 / 07 / 21",
  },
];

const CallFinishedContainer = (): JSX.Element => (
  <>
    <CardsContainer>
      <Card flexDirection="column" alignItems="center" justifyContent="center">
        <Text
          color="lightBlue"
          fontWeight="600"
          fontSize="36px"
          lineHeight="1.5"
        >
          Jorge Martínez
        </Text>
        <Text fontSize="26px" lineHeight="1.5">
          Lucas Films | CEO
        </Text>
        <Text fontSize="26px" lineHeight="1.5">
          55 32 501 520
        </Text>
      </Card>
      <Card></Card>
      <Card></Card>
    </CardsContainer>
    <Footer />
  </>
);

export default CallFinishedContainer;
