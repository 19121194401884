import routes from "../../constants/routes";
import axios from "axios";
import { SalesforceApp } from "../../types/SalesforceTypes";

type SalesforceConfigProps = {
  salesforceConfig?: SalesforceApp;
  error?: { message: string };
};

const getSalesforceConfigAsync = async (
  userId: string,
  orgId: string
): Promise<SalesforceConfigProps> => {
  try {
    const salesforceConfig = await axios.get<SalesforceApp>(
      `${routes.get.salesforce.getSalesforceConfiguration}/${orgId}/${userId}`
    );

    return { salesforceConfig: salesforceConfig?.data };
  } catch (error) {
    return { error: { message: "Error while getting Salesforce configuration" } };
  }
};

export default getSalesforceConfigAsync;
