
export class LogPolicies {
    notifySDR!: boolean;
    notifyAgent!: boolean;
    logBetterStack!: boolean;

    constructor(notifySDR: boolean, notifyAgent: boolean) {
        this.notifySDR = notifySDR;
        this.notifyAgent = notifyAgent;
        this.logBetterStack = true;
    }
}

export const LogLevels = {
    WarnAll: new LogPolicies(true, true),
    WarnSDR: new LogPolicies(true,false),
    WarnAgent: new LogPolicies(false,true),
    OnlyLog: new LogPolicies(false, false),
};

class RequestTimeoutConfig {
    name!: string;
    slug?: string;
    maxTimeAllowed!: number;
    logLevel!: LogPolicies;
}


/** This object help to introduce more endpoints, their time of tolerance and logging policies. */
export const requestTimeoutConfig:RequestTimeoutConfig[] = [
    {name:'dashboard', slug: 'dashboard/sdrId', maxTimeAllowed: 5000, logLevel: LogLevels.WarnSDR},
    {name: 'dashboard-details', slug: 'dashboard/session-details', maxTimeAllowed: 5000, logLevel: LogLevels.WarnSDR},
    {name: 'default', maxTimeAllowed: 800, logLevel: LogLevels.WarnAll},
    {name:'hubspot', slug: 'hubspot-configs', maxTimeAllowed: 10000, logLevel: LogLevels.WarnSDR},
    {name: 'hubspot-custom', slug:'hubspot-configs-custom', maxTimeAllowed: 10000, logLevel: LogLevels.WarnSDR},
    {name: 'hubspot-mappings', slug:'hubspot-mappings', maxTimeAllowed: 10000, logLevel: LogLevels.WarnSDR},
    {name: 'login', slug: 'login', maxTimeAllowed: 5000, logLevel: LogLevels.WarnAll},
    {name:'outreach', slug: 'outreach', maxTimeAllowed: 10000, logLevel: LogLevels.WarnSDR},
    {name: 'outreach-mappings', slug:'outreach-mappings', maxTimeAllowed: 10000, logLevel: LogLevels.WarnSDR},
    {name:'salesforce', slug: 'salesforce-configs', maxTimeAllowed: 10000, logLevel: LogLevels.WarnSDR},
    {name:'salesforce-custom', slug:'salesforce-configs-custom', maxTimeAllowed: 10000, logLevel: LogLevels.WarnSDR},
];

