import React, { type ChangeEvent, type FC, type FormEvent, useEffect, useState } from 'react';
import LayoutView from '@views/Layout/Layout.view';
import UICardComponent from '@views/Layout/Components/UICard.component';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@components/ui/table';
import moment from 'moment-timezone';
import { IconZoomScan } from '@tabler/icons-react';
import { Button } from '@components/ui/button';
import SearchBarComponent from '@/src-v2/Presentation/components/shared/SeachBar.component';
import PaginatorControlsComponent from '@/src-v2/Presentation/components/shared/PaginatorControls.component';
import { DashboardViewmodel } from '@views/Dashboard/Dashboard.viewmodel';
import ReportsModalComponent from '@views/Dashboard/Components/ReportsModal.component';
import UILoader from '@/src-v2/Presentation/components/shared/UILoader';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@components/ui/tooltip';
import renderMappedIcon from '@/src-v2/Presentation/Common/Components/customElements/ContactIconTypeMap';
import useScreenSize from '@/src/core/hooks/useScreenSize';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '@/src/constants/routesConnect';
import tabTitle from '@/src/utils/updateTitle';

const DashboardView: FC = () => {
  const [queryValue, setQueryValue] = useState('');
  const [loading, setLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const { paginationData, dashboardData, timezone, fetchData, renderSummary } = DashboardViewmodel();
  const screenSize = useScreenSize();
  const bodyTableCell = 'text-center';
  const dashboardHeaders = [
    'Id',
    'Contact List Name',
    'User Name',
    'List type',
    'Start at',
    'Contacts',
    'Organization',
    'Actions',
  ];
  const proportionConstant: number = Math.ceil(screenSize.height - screenSize.height / 3.8);

  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const pageParam = queryParams.get('page');

  const searchParam = queryParams.get('search');

  tabTitle('Tendril Connect | Dashboard');

  function captureSearchQuery(e: ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setQueryValue(e.target.value);
  }

  function submitQuery(e: FormEvent): void {
    e.preventDefault();
    setPageNumber(1);

    localStorage.setItem('lastDashboardSearch', queryValue);
    localStorage.setItem('lastDashboardPage', '');
    if (queryValue === '') {
      localStorage.setItem('lastDashboardSearch', '');
      localStorage.setItem('lastDashboardPage', '');
    }
    setQueryValue(queryValue);
    setPageNumber(1);

    navigate(`/${PrivateRoutes.DASHBOARD_V2}?search=${queryValue}&page=1`, { replace: true });
    void fetchData(1, queryValue);
  }

  const handlePrev = (): void => {
    setPageNumber((last) => {
      handleQueryParams(last - 1, queryValue);
      localStorage.setItem('lastDashboardPage', (last > 1 ? last - 1 : 1).toString());
      return last - 1;
    });
    void fetchData(pageNumber, queryValue);
  };

  const handleNext = (): void => {
    setPageNumber((last) => {
      handleQueryParams(last + 1, queryValue);
      localStorage.setItem('lastDashboardPage', (last + 1).toString());
      return last + 1;
    });
    void fetchData(pageNumber, queryValue);
  };

  const handleQueryParams = (page: number, search: string): void => {
    if (page > 0) {
      const queryParams = new URLSearchParams();
      queryParams.set('page', page.toString());
      queryParams.set('search', search);
      navigate({
        pathname: window.location.pathname,
        search: queryParams.toString(),
      });
    }
  };

  useEffect(() => {
    void fetchData(
      pageParam !== null && pageParam !== undefined ? +pageParam : pageNumber,
      searchParam !== null && searchParam !== undefined ? searchParam : queryValue,
    ).then(() => {
      setLoad(false);
      if (pageParam !== null && pageParam !== undefined) {
        setPageNumber(+pageParam);
      }
      if (searchParam !== null && searchParam !== undefined) {
        setQueryValue(searchParam);
      } else {
        setQueryValue('');
      }
    });
  }, [pageNumber, searchParam]);

  return (
    <LayoutView>
      {loading ? (
        <UILoader message={'Loading call sessions, please wait...'} />
      ) : (
        <UICardComponent
          title="Dashboard"
          width="full"
          height="[100%]"
          minHeight="[400px]"
          showTitle={false}
          footer={
            paginationData.totalCount > paginationData.pageSize ? (
              <PaginatorControlsComponent
                pageNumber={paginationData.pageNumber}
                totalPages={paginationData.totalPages}
                totalCount={paginationData.totalCount}
                pageSize={paginationData.pageSize}
                onPrevPage={handlePrev}
                onNextPage={handleNext}
              />
            ) : null
          }
        >
          <SearchBarComponent
            data-qa-id="searchBarDashboard"
            changeFunction={captureSearchQuery}
            submitFunction={submitQuery}
          ></SearchBarComponent>
          <div
            style={{ height: screenSize.height > 700 ? `${proportionConstant}px` : '100%' }}
            className={`${
              screenSize.height < 700 ? 'max-h-[570px] min-[1500px]:max-h-[570px] min-[1024px]:max-h-[500px]' : ''
            } overflow-y-scroll`}
          >
            <Table className="max-[1400px]:max-w-full">
              <TableHeader className="animate-table-soft-transition">
                {dashboardHeaders.map((header, index) => {
                  return (
                    <TableCell key={`dashboard-header-${index}`} className="text-center bg-primary text-white">
                      {header}
                    </TableCell>
                  );
                })}
              </TableHeader>
              <TableBody>
                {dashboardData?.data.map((session, index) => {
                  const {
                    sessionData: { id: callSessionId, shortId, startAt, listNames, type, totalContacts },
                    sdrData: { name: sdrName },
                    organizationData: { name: orgName },
                  } = session;
                  const formattedData = moment(startAt.$date.toString())
                    .tz(timezone as string)
                    .format('llll');
                  return (
                    <TableRow className="animate-table-soft-transition" key={index} id={callSessionId}>
                      <TableCell className={bodyTableCell}>{shortId}</TableCell>
                      <TableCell className={bodyTableCell}>{listNames}</TableCell>
                      <TableCell className={`${bodyTableCell} flex-col`}>{sdrName}</TableCell>
                      <TableCell className={`${bodyTableCell} flex-col`}>{renderMappedIcon(type)}</TableCell>
                      <TableCell className={bodyTableCell}>{formattedData}</TableCell>
                      <TableCell className={bodyTableCell}>{totalContacts}</TableCell>
                      <TableCell className={`${bodyTableCell} flex-col`}>{orgName}</TableCell>
                      <TableCell className={bodyTableCell}>
                        <div className="flex flex-row gap-2 max-[1180px]:flex-col justify-center align-middle min-[1024px]:gap-0">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <Button
                                  id="showSummary"
                                  data-qa-id={`showSummary-${shortId}`}
                                  variant="outline"
                                  size="icon"
                                  className="border-none"
                                  onClick={() => {
                                    renderSummary(callSessionId);
                                  }}
                                >
                                  <IconZoomScan />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Summary</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          <ReportsModalComponent callSessionId={callSessionId} type={type} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </UICardComponent>
      )}
    </LayoutView>
  );
};

export default DashboardView;
