import React from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { customOutreachLogo } from '@/src/core/ui/icons/CustomOutreachLogo';
import { customHubspotLogo } from '@/src/core/ui/icons/CustomHubspotLogo';
import { customTendrilNewLogo } from '@/src/core/ui/icons/TendrilLogoNew';
import { customSalesforceLogo } from '@/src/core/ui/icons/CustomSalesforceLogo';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

function renderMappedIcon(name: string): React.JSX.Element {
  const iconMap: Record<string, React.JSX.Element> = {
    OUTREACH: <FA icon={customOutreachLogo} className="text-app-integrations-outreach h-5 w-5" />,
    HUBSPOT: <FA icon={customHubspotLogo} className="text-app-integrations-hubspot h-5 w-5" />,
    CUSTOM_HUBSPOT: <FA icon={customHubspotLogo} className="text-app-integrations-hubspot h-5 w-5" />,
    SALESFORCE: <FA icon={customSalesforceLogo} className="text-app-blue-light h-5 w-5" />,
    CSV: <FA icon={customTendrilNewLogo} className="text-app-blue-light h-5 w-5" />,
    FOURMATIV: <FA icon={faFileCsv} className="h-5 w-5" />,
    '': <FA icon={faFileCsv} className="h-5 w-5" />,
  };
  return <div className="text-center">{iconMap[name]}</div>;
}

export default renderMappedIcon;
