import React, { type FC, useContext } from 'react';
import { SidebarData as sideBarData } from '@/src-v2/Presentation/Views/Layout/Components/sideBarData';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useOutletContext } from 'react-router-dom';
import { featuresStore } from '@/src/components/app/FeaturesProvider';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import TendrilLogo from '@/public/assets/icons/tendril_sidebar.svg';
import BetaFeatureLabel from '@/src-v2/Presentation/Common/Components/customElements/BetaFeatureLabel';
import UIStatsComponent from '@views/Layout/Components/UIStats.component';

interface SideBarProps {
  opened: boolean;
}
const SidebarComponent: FC<SideBarProps> = ({ opened }) => {
  const { featuresState } = useContext(featuresStore);
  const { pathname } = useLocation();
  const { userSession } = useOutletContext<OutletContextProps>();
  const { dashboardDetails } = featuresState;

  return (
    <div
      id="sideBar"
      className={`z-7 grid-area-content flex-none gap-4 bg-primary h-full
      ${opened ? 'w-72 ' : 'w-12 '}`}
      style={{ transition: '0.3s' }}
    >
      <nav className="w-full">
        <ul className="flex flex-col text-white text-xl font-semibold">
          {sideBarData
            .filter((item) => {
              if (item.restrictedToAdmin === false || item.restrictedToAdmin === undefined) return true;
              return userSession.isAdmin && item.restrictedToAdmin;
            })
            .map(({ path, id, title, icon, isRedirecting, isHidden, isBeta }, index) => (
              <li
                data-qa-id={id}
                key={`${index}-${title}`}
                className={`${path === pathname ? 'bg-white text-[#0A6B8C] ' : ''} hover:text-[#0A6B8C] hover:bg-white`}
              >
                <Link
                  className={`flex items-center w-full ml-3 py-4 gap-3 text-inherit no-underline transform transition ease-in duration-6500
                      ${path === pathname ? 'bg-white text-[#0A6B8C]' : ''} 
                      ${isHidden === true ? 'hidden' : ''} 
                      ${Boolean(dashboardDetails) && isBeta === true ? 'hidden' : ''}
                      ${!opened ? 'mt-[3px] my-[12px] px-0' : ''}
                    `}
                  to={isRedirecting === true ? path : '/home'}
                >
                  <FA icon={['fas', icon]} />
                  {opened && (
                    <>
                      {title}
                      {isBeta === true && <BetaFeatureLabel />}
                    </>
                  )}
                </Link>
              </li>
            ))}
        </ul>
      </nav>
      <UIStatsComponent opened={opened} />
      <footer className="w-full self-stretch grid grid-cols-1 justify-items-center gap-4 pb-4 mt-auto">
        <img width="80rem" height="80rem" src={TendrilLogo as unknown as string} alt="Tendril logo" />
      </footer>
    </div>
  );
};

export default SidebarComponent;
