import React, { type FC, type ReactNode } from 'react';

interface UICardProps {
  title: string;
  showTitle?: boolean;
  width?: string;
  minHeight?: string;
  height?: string;
  children: ReactNode;
  footer?: ReactNode;
}

const UICardComponent: FC<UICardProps> = ({
  title,
  showTitle = true,
  width = 'full',
  minHeight = 'none',
  height = 'none',
  children,
  footer,
}) => {
  return (
    <div className={`grid w-${width} bg-white text-app-gray-500 my-1 ml-0.5 mr-1 rounded-md`}>
      {showTitle && (
        <div
          id="cardTitle"
          className="max-h-screen text-center text-white font-bolder p-[1em] h-[300px]text-white bg-app-blue-lighter text-xl"
        >
          {title}
        </div>
      )}
      <div
        id="cardBody"
        className={`grid h-${height} min-h-${minHeight} animate-table-soft-transition relative flex flex-col flex-wrap gap-1 p-1 min-w-[900px]`}
      >
        {children}
      </div>
      <footer className="flex flex-row  min-w-[900px] max-[1400px]:max-w-[90%] justify-end pt-[4rem] min-[1024px]:pt-[0.5rem]">
        {footer}
      </footer>
    </div>
  );
};

export default UICardComponent;
