import React, { useEffect, useState, useContext } from "react";
import { SidebarData, SidebarItemData } from "./SideBarData";
import TendrilLogo from "../../../public/assets/icons/tendril_sidebar.svg";
import getCurrentSessionStats from "../../api/Metric/getCurrentSessionStats";
import { CurrentCallSessionMetrics } from "../shared/CurrentCallSessionMetrics";
import { UserProviderContext } from "../app/UserProvider";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

import {
  UISidebar,
  UISidebarNav,
  UISidebarBottom,
  UILink,
} from "../../core/ui/UIElements";

import { UIStatsBox } from "../../core/ui/UIStatsBox";
import NavDisabledModal from "./NavDisabledModal";
import {featuresStore} from "../app/FeaturesProvider";
import BetaFeatureLabel from "../../../src-v2/Presentation/Common/Components/customElements/BetaFeatureLabel";

type SideBarProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: { [key: string]: any } | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIntervals: (value: any) => void | undefined;
  callStatus?: string | undefined;
  sdrStatus: string | undefined;
};

const SideBar = ({
  intervals,
  setIntervals,
  callStatus,
  sdrStatus,
}: SideBarProps): React.JSX.Element => {
  const [currentSessionStats, setCurrentSessionStats] = useState<
    CurrentCallSessionMetrics | null | undefined
  >(null);

  const { currentUser } = useContext(UserProviderContext);
    const { featuresState } = useContext(featuresStore);
  const { pathname } = useLocation();

  const [isNavDisabledModalOpen, setIsNavDisabledModalOpen] =
    useState<boolean>(false);

  const optionsToHide = ["Contacts", "Voice", "Intent"];
  const [sideBarOpts, setSideBarOpts] =
    useState<SidebarItemData[]>(SidebarData);

  useEffect(() => {
    const fetchCurrentSessionStats = async () => {
      const { currentSessionStats, error } = await getCurrentSessionStats();
      if (!error) {
        setCurrentSessionStats(currentSessionStats);
      } else {
        setCurrentSessionStats(null);
      }
    };
    fetchCurrentSessionStats();
    const newInterval = intervals || {};
    newInterval["sideBarCurrentSessionStats"] = setInterval(() => {
      fetchCurrentSessionStats();
    }, 30000);
    setIntervals(newInterval);
    return () => {
      clearInterval(intervals?.sideBarCurrentSessionStats);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (callStatus === "paused" && sdrStatus === "busy") ||
      callStatus === "started"
    ) {
      setSideBarOpts((prev) =>
        prev.map((item) => ({
          ...item,
          isRedirecting: false,
          isHidden: optionsToHide.includes(item.title),
        }))
      );
    } else {
      setSideBarOpts(SidebarData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStatus]);

  return (
    <UISidebar>
      <UISidebarNav>
        <ul>
          {sideBarOpts
            .filter((item) => {
              if (!item.hasOwnProperty("restrictedToAdmin")) return true;
              return currentUser.isAdmin && item.restrictedToAdmin;

            })
            .map(({ path, icon, title, isRedirecting, isHidden, isBeta }) => (
              <li key={title}>
                {
                  <UILink
                    className={`
                      ${path === pathname && "selected"} 
                      ${isHidden && "hidden"} 
                      ${(!featuresState.dashboardDetails && isBeta) && "hidden"}
                    `}
                    to={isRedirecting ? path : "/home"}
                    onClick={() => setIsNavDisabledModalOpen(!isRedirecting)}
                  >
                    <FA icon={["fas", icon]} />
                    {title}{isBeta && <BetaFeatureLabel />}
                  </UILink>
                }

              </li>

            ))}
        </ul>
        <UIStatsBox
          title="Current call session stats"
          rows={[
            { name: "Number of calls", data: currentSessionStats?.called || 0 },
            {
              name: "Number of voicemails",
              data: currentSessionStats?.voicemail || 0,
            },
            {
              name: "Number of transfers",
              data: currentSessionStats?.transferred || 0,
            },
            {
              name: "Number of total contacts",
              data: currentSessionStats?.totalContacts || 0,
            },
          ]}
        />
      </UISidebarNav>

      <NavDisabledModal
        isOpen={isNavDisabledModalOpen}
        onClose={() => setIsNavDisabledModalOpen(false)}
      />

      <UISidebarBottom>
        <img
          width="80rem"
          height="80rem"
          src={TendrilLogo}
          alt="Tendril logo"
        />
      </UISidebarBottom>
    </UISidebar>
  );
};

export default SideBar;
