import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type CalendarResponse } from '@datasource/edge/calendar/entities/calendar.entity';

async function getCalendlySession(userId: string, cache: boolean): Promise<ApiResponse<CalendarResponse>> {
  try {
    const sessions: ApiResponse<CalendarResponse> = await axios.get(`${routes.get.calendar.getSessions}/${userId}`, {
      params: { cache },
    });
    return { data: sessions.data, success: true };
  } catch {
    return { error: { message: 'Error getting sessions' }, success: false };
  }
}
export default getCalendlySession;
