import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Layout from "../../components/shared/Layout";
import {
  StartContainer,
  CallContainer,
  CallFinishedContainer,
} from "../../components/dialerAgent";
import { Action, CallSessionSuccessResponse } from "../../components/shared/CallSession";
import { Connection, Device } from "twilio-client";
import { Contact } from "../../components/shared/Contact";
import getByStatusAndAgentIdAsync from "../../api/CallSession/getByStatusAndAgentIdAsync";
import getByIdAsync from "../../api/CallSession/getByIdAsync";
import { ThemeProviderWrapperContext } from "../../components/app/ThemeProviderWrapper";
import DispositionsModal from "../../components/dispositions/DispositionsModal";
import { getAgentCallSessionValidations } from "../../core/utils/validations/agentValidations";
import tabTitle from "../../utils/updateTitle";
import { featuresStore } from "../../components/app/FeaturesProvider";
import { UserProviderContext } from "../../components/app/UserProvider";
import getUserInfoAsync from "../../api/Users/getUserInfo";
import { hideUIToast, showUIToast } from "../../core/ui/UIToast";
import updateCallLogAsync from "../../api/CallLog/updateCallLogAsync";
import getTwilioToken from "../../api/Twilio/getTwilioToken";
import twilioTransferCall from "../../api/Twilio/twilioTranferCall";
import ErrorBoundary from "../../components/ErrorBoundary";
import useMicrophone from "../../core/hooks/useMicrophone";
import MicrophoneSetupModal from "./components/MicrophoneSetupModal";
import { CallLogStatus } from "../../types/CallLogTypes";
import {useOutletContext} from "react-router-dom";
import getCallLogAsync from "../../api/CallLog/getCallLogAsync";
import { CurrentUser } from "../../types";
import CustomSounds from "../../../src-v2/Domain/enums/Twilio/custom-sounds";
import { useLogger } from "../../components/app/LoggerProvider";
import updateCallSessionAsync from "../../api/CallSession/updateCallSessionAsync";
import updateContactNotesAsync from "../../api/Contacts/updateContactNotesAsync";
import audioStart from "../../../public/assets/icons/notification-18-270129.mp3";

const window = globalThis as any;
const SDR_TOAST_STATUS_ID = "sdrToastStatus";
const options = [
  { value: "LVM", label: "Left Voicemail" },
  { value: "NA", label: "No Answer" },
  { value: "GC", label: "Gatekeeper Conversation" },
  { value: "PTNC", label: "Phone Tree No Connection" },
  { value: "BN/BS", label: "Bad Number/Busy Signal" },
  { value: "WN", label: "Wrong Number" },
  { value: "RAC", label: "Referred to another contact" },
  { value: "NLC", label: "No Longer With Company" },
  { value: "PR", label: "Phone Refusal (hangup)" },
  { value: "RTT", label: "Refuse to transfer" },
  { value: "OO", label: "Opt Out" },
];
const DialerAgent = (): JSX.Element => {
  const {userSession, twilioDevice, setTwilioDevice, handleRoutesLogout, intervals, setIntervals} = useOutletContext() as any;
  const { currentUser, setCurrentUser } = useContext(UserProviderContext);
  const [step, setStep] = useState<number>(1);
  const [onPhone, setOnPhone] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [showedCallSessionStatusToast, setCallSessionStatusToast] =
    useState<string>("");
  const [callId, setCallId] = useState<string>("");
  const [callTransferred, setCallTransferred] = useState<boolean>(false);
  const [connection, setConnection] = useState<Connection | undefined | null>({} as Connection);
  const { changeTheme } = useContext(ThemeProviderWrapperContext);
  const [currentCallSession, setCurrentCallSession] =
    useState<CallSessionSuccessResponse | null>(null);
  const [currentContact, setCurrentContact] = useState<Contact>({} as Contact);
  const [showModal, setShowModal] = useState(false);
  const [submittedInfo, setSubmittedInfo] = useState<boolean>(true);
  const [showAdditionalNotes, setShowAdditionalNotes] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "",
  });
  const [notesAgent, setNotesAgent] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [submittedDisabled, setSubmittedDisabled] = useState<boolean>(true);
  const [isTransferDisabled, setTransferDisabled] = useState<boolean>(false);
  const [lastCallId, setLastCallId] = useState<string>("");
  const [friendlyNameVM, setfriendlyNameVM] = useState<string>("");
  const { featuresState } = useContext(featuresStore);
  const [errorBoundary, setErrorBoundary] = useState<boolean>(false);
  const [queueElements, setQueueElements] = useState<number>(0);
  const { isMicrophoneAllowed, setupMicrophonePermissions } = useMicrophone();
  const [isOngoingCall, setIsOngoingCall] = useState<boolean>(false);
  const [isCallCutOff, setIsCallCutOff] = useState<boolean>(false);
  const [dialedNumber, setDialedNumber] = useState<string>('');
  const {logger} =  useLogger();

  const loggerRef = useRef(logger);
  const currentCallSessionRef = useRef(currentCallSession);
  const isCallCutOffRef = useRef(isCallCutOff);
  const callTransferredRef = useRef(callTransferred);
  const currentContactRef = useRef(currentContact);
  const dialedNumberRef = useRef(dialedNumber);
  const intervalId = useRef(null);
  const validCallSession = useRef<boolean>(false);

  tabTitle("Tendril Connect | Home");


  useEffect(() => {
    loggerRef.current = logger;
  }, [logger]);


  useEffect(() => {
     currentCallSessionRef.current = currentCallSession;
     isCallCutOffRef.current = isCallCutOff;
     callTransferredRef.current = callTransferred;
     currentContactRef.current = currentContact;
     dialedNumberRef.current = dialedNumber;
  }, [currentCallSession, isCallCutOff, callTransferred, currentContact, dialedNumber]);


  useEffect(() => {
    (async () => {
      const { twilioTokenResponse, error } = await getTwilioToken(
          userSession.userId
      );
      const token = twilioTokenResponse?.token;
      if (error || !twilioTokenResponse || !token) {
        // Request made and server responded
        showUIToast({
          type: "warning",
          text: "Twilio requires attention. If the problem persists, please contact customer support. ",
        });
        setErrorBoundary(true);
        console.log(error?.message)
        loggerRef.current.error("Error getting twilio token.",  {
          componentName: DialerAgent.name,
          userId: userSession.userId
        })
        return;
      }

      const twilioDevice = new Device();

      twilioDevice.setup(token, { codecPreferences: [ Connection.Codec.Opus, Connection.Codec.PCMU], sounds: {
        incoming: CustomSounds.INCOMING,
        outgoing: CustomSounds.OUTGOING,
        disconnect: CustomSounds.DISCONNECT,
      } });

      setTwilioDevice(twilioDevice);

      twilioDevice.on("ready", function () {
        showUIToast({ type: "default", text: "Connected" });
      });

      twilioDevice.on("disconnect", function (e:any) {
        OnCallFinished();
      });

      twilioDevice.on("connect", function (connection: Connection) {
        setCallId(connection.parameters.CallSid);
        setfriendlyNameVM(connection.parameters.CallSid);
        setLastCallId(connection.parameters.CallSid);
      });

      twilioDevice.on('error', function(e) {
        if(e.twilioError.code === 31005) {
          showUIToast({
            type: "error",
            text: "Call failed due to wrong number or regional blocks.",
          });
          loggerRef.current.error("Call failed due to wrong number or regional blocks.",  {
            componentName: DialerAgent.name,
            userId: userSession.userId,
            sdrId: userSession.sdrId,
            organizationId: userSession.organizationId,
            sessionId: currentCallSessionRef.current ? currentCallSessionRef.current.id : '',
            contactListId: currentCallSessionRef.current? currentCallSessionRef.current.contactList[0].contactListId : '',
            phone:currentContactRef.current.phone,
            phoneDialed: dialedNumberRef.current !== "" ? dialedNumberRef.current : currentContactRef.current.phone,
          })

          setDialedNumber('');
        }
    });

    })();
  }, []);

  // TODO: Change _id usage to id (or add compatibility)
  const handleToggleCall = async (
    number: string,
    currentContact: Contact,
    contactListId: string
  ) => {
    setIsOngoingCall(false);

    setTransferDisabled(true);

    if (!onPhone) {
      setSubmittedDisabled(false);

      setOnPhone(true);

      // make outbound call with current number
      twilioDevice.connect({
        agent: currentUser.userId,
        number: number,
        contactListId: contactListId,
        callSessionId: currentCallSession!.id,
        contactId: currentContact._id,
      });
      changeTheme("busy");
    } else {
      twilioDevice.disconnectAll();
    }
  };

  const toggleMute = (state) => {
    if (callTransferred) {
      return showUIToast({
        type: "warning",
        text: "Unmute isn't allowed after a transfer. ",
      });
    }

    setIsMuted(state);
    twilioDevice.activeConnection()?.mute(state);
    showUIToast({ type: "info", text: state ? "Muted" : "Unmuted" });
  };

  const getCallSessionById = async (id) => {
    const { callSession, error } = await getByIdAsync(id);

    if (error) {
      showUIToast({ type: "error", text: error.message });
      return;
    }

    if (
      callSession &&
      (callSession.status !== currentCallSession?.status ||
        callSession.sdrStatus !== currentCallSession?.sdrStatus)
    ) {

      setCurrentCallSession(callSession);

      if (callSession.status === "paused") {

        showUIToast({
          type: "warning",
          text: "Session paused, you can't make calls until it is started again.",
        });

        changeTheme("paused");

        const {action, userId} = callSession.action;

        const activeConnection = twilioDevice.activeConnection();

        if(activeConnection && action === Action.PAUSED ){

          activeConnection.disconnect();

          showUIToast({
            type: "warning",
            text: "Call ended because the session is paused.",
          });

          loggerRef.current.info("Call ended because the session is paused.",  {
            componentName: DialerAgent.name,
            userId: userSession.userId,
            sdrId: userSession.sdrId,
            sessionId: currentCallSessionRef.current ? currentCallSessionRef.current.id : '',
            contactListId: currentCallSessionRef.current? currentCallSessionRef.current.contactList[0].contactListId : '',
            contactId: currentContactRef.current.id,
            userAction: JSON.stringify(callSession.action),
          });
        } else if (activeConnection && action === Action.TRANSFERRED && userId !== currentUser.userId) {

          setIsCallCutOff(true);

          activeConnection.disconnect();

          showUIToast({
            type: "warning",
            text: "Transfer in progress, you can't make calls until it is started again.",
          });

          loggerRef.current.info("Call ended by incoming transfer.",  {
            componentName: DialerAgent.name,
            userId: userSession.userId,
            sdrId: userSession.sdrId,
            sessionId: currentCallSessionRef.current ? currentCallSessionRef.current.id : '',
            contactListId: currentCallSessionRef.current? currentCallSessionRef.current.contactList[0].contactListId : '',
            contactId: currentContactRef.current.id,
            userAction: JSON.stringify(callSession.action),
          });

        };

      } else if (callSession.status === "started") {
        showUIToast({
          type: "info",
          text: "Session started, you can make calls again.",
        });

        const audio = new Audio(audioStart);
        audio.play().catch((error) => console.error("Error playing alert tone"));
        changeTheme("free");

        setCallSessionStatusToast("paused");
      } else {

        changeTheme("free");

        setCallSessionStatusToast("");

        if (onPhone) {
          twilioDevice.disconnectAll();
        };

        hideUIToast(SDR_TOAST_STATUS_ID);

        setStep(1);
      };
    }
  };

  const handleTransferCall = async (contactId) => {
    setTransferDisabled(true);
    if (currentCallSession) {
      const { callSession } = await getByIdAsync(currentCallSession.id);
      if (callSession?.sdrStatus === "free") {

        await updateCallSessionAsync({
          data: {
            ...callSession,
            action: {
              action: Action.TRANSFERRED,
              userId: currentUser.userId
            }
          }, id: callSession.id
        });

        const { twilioCallResponse, error } = await twilioTransferCall({
          callId: callId,
          sdrId: currentUser.sdrId!,
          organizationId: currentUser.organizationId,
          callSessionId: currentCallSession.id,
          agentId: currentUser.userId,
        });

        if (error) {
          twilioDevice.disconnectAll();
          if (error.status === 423) {
            showUIToast({
              type: "error",
              text: "Sorry, your User is busy, try again later.",
            });
            // TODO: Do we need update the callLog?
            setErrorBoundary(true);
          } else if (error.status === 404) {
            showUIToast({
              type: "error",
              text: "Sorry, we can not transfer your call.",
            });
            setErrorBoundary(true);
            const { success } = await updateCallLogAsync({
              callId,
              status: "transfer_error",
              disposition: "SDNPU",
            });
            throw new Error(error.message);
          } else {
            // TODO: disconnect call
            setErrorBoundary(true);
            showUIToast({
              type: "error",
              text: error.message,
            });
            throw new Error(error.message);
          }
          cleanDispositionInfo();
          changeTheme("free");
          setTransferDisabled(false);

          return;
        }

        setSubmittedInfo(true);

        setCallTransferred(true);

        setIsMuted(true);

        twilioDevice.activeConnection()?.mute(true);

        return;
      }
      showUIToast({
        toastId: SDR_TOAST_STATUS_ID,
        type: "warning",
        text: `User is busy.`,
      });
    }
  };

  const getCallSessionByStatusAndAgentId = async () => {
    const { callSession, error } = await getByStatusAndAgentIdAsync({
      status: "started",
      agentId: currentUser.userId,
    });

    if (error) {
      setErrorBoundary(true);
      showUIToast({
        type: "error",
        text: "Error while getting call status",
      });
      throw new Error(error.message);
    }

    if (callSession) {
      showUIToast({
        type: "default",
        text: "Connected",
      });
      setCurrentCallSession(callSession);
      setStep(2);
    }
  };

  const handleStartCall = useCallback(async (): Promise<void> => {
    setIsOngoingCall(false);
    setTransferDisabled(true);
    if (!isMicrophoneAllowed) {
      await setupMicrophonePermissions({ showToast: true });
      return;
    }
    const { user } = await getUserInfoAsync(currentUser.userId);

    if (!user) {
      return showUIToast({
        type: "error",
        text: "An error has occurred while obtaining agent information.",
      });
    };

    if (currentUser.sdrId !== user?.sdrId) {
      const localStorageUser = JSON.parse(
        window.localStorage.getItem("user") || "{}"
      );

      const currentUser: CurrentUser = {
      ...localStorageUser,
      ...user,
      }
      setCurrentUser(currentUser);
      setCurrentCallSession(null);
      cleanDispositionInfo();
    }

    const isCallSessionValid = await getAgentCallSessionValidations(
      user.userId,
      user.sdrId
    );

    validCallSession.current = isCallSessionValid;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isMicrophoneAllowed, queueElements]);

  const startInterval = useCallback(() => {
    intervalId.current = window.setInterval(async () => {
      if (!validCallSession.current) {
        await handleStartCall()
      } else {
        window.clearInterval(intervalId.current);
        await getCallSessionByStatusAndAgentId();
      }
    }, 2000);
  }, [validCallSession.current]);

  const onGoingCall = async (callId: string) => {
    const { data, error } = await getCallLogAsync(callId);
    if (error) {
      showUIToast({
        type: "error",
        text: "An error occurred while obtaining the call status.",
      });
    }
    if (data && data.status === "ongoing" && !isOngoingCall) {
      setTransferDisabled(false);
      setIsOngoingCall(true);
    }

    if (data && data.status === "finished") {
      setTransferDisabled(true);
      setIsOngoingCall(false);
    }
  };

  useEffect(() => {
    const callValidityInterval = setInterval(async () => {
      if (step === 1) {
        return;
      }
      const isCallSessionValid = await getAgentCallSessionValidations(
        currentUser.userId,
        currentUser.sdrId
      );
      !isCallSessionValid && setStep(1);
    }, 10000);

    return () => clearInterval(callValidityInterval);
  }, [handleStartCall, step, currentUser]);

  useEffect(() => {
    const ongoingInterval = setInterval(async () => {
      if (lastCallId && lastCallId !== "" && !isOngoingCall) {
        await onGoingCall(lastCallId);
      }
    }, 1000);

    return () => clearInterval(ongoingInterval);
  }, [lastCallId, isOngoingCall, isTransferDisabled]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (currentCallSession?.id) {
      interval = setInterval(() => {
        getCallSessionById(currentCallSession.id);
      }, 3000);
    }

    //necessary to clean up the interval
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCallSession?.id,
    currentCallSession?.status,
    currentCallSession?.sdrStatus,
  ]);

  useEffect(() => {
    if (currentCallSession?.sdrStatus) {
      const status = currentCallSession?.sdrStatus;
      changeTheme(
        status === "free" && currentCallSession.status === "paused"
          ? "paused"
          : status
      );
      showUIToast({
        toastId: SDR_TOAST_STATUS_ID,
        type: "info",
        text: `User is ${status}.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCallSession?.sdrStatus]);

  useEffect(() => {
    if (!onPhone && Object.keys(currentContact).length > 0) {
      cutOffValidation().then();

      if (!callTransferredRef.current && isCallCutOffRef.current) {
        setShowModal(true);
        setCallTransferred(false);
        setIsCallCutOff(false);
      } else {
        if(callTransferredRef.current){
          cleanDispositionInfo();
        }else{
          setShowModal(true);
        };
      }
    }
  }, [onPhone]);

  const cutOffValidation = async () => {

    if (currentCallSession?.status === "paused" && !callTransferred) {
      const status: CallLogStatus = "cut_off";
      const { error } = await updateCallLogAsync({
        callId: lastCallId,
        status,
      });
      if (error) {
        showUIToast({
          type: "error",
          text: "Error updating call log, try again later.",
        });
      }
    }
  };

  const OnCallFinished = () => {
    setOnPhone(false);
    setCallId("");
    changeTheme("free");
    setIsMuted(false);
    setTransferDisabled(true);
    setIsOngoingCall(false);
  };

  const logout = () => {
    handleRoutesLogout();
    if (intervals["dialingStatus"]) clearInterval(intervals["dialingStatus"]);
  };

  const onDropdownChange = (event) => {
    setSelectedOption(event);
    setIsDisabled(event.value !== "");
    setShowAdditionalNotes(event.value === "OTR");
  };

  const cleanDispositionInfo = () => {
    setNotesAgent("");
    setShowAdditionalNotes(false);
    setShowModal(false);
    setCallTransferred(false);
    setLastCallId("");
    setCurrentContact({} as Contact);
    setSelectedOption({
      value: "",
      label: "",
    });
    setIsDisabled(false);
    setSubmittedDisabled(false);
  };

  const handleSelectedOption = async () => {

    if (lastCallId && selectedOption.value !== "") {
      const { error } = await updateCallLogAsync({
        callId: lastCallId,
        status: "finished",
        notesAgent: notesAgent,
        disposition: selectedOption.value,
      });

      if (error) {
        showUIToast({
          type: "error",
          text: `Error updating call log, try again later.`,
        });
        setShowModal(false);
        setErrorBoundary(true);
        throw new Error(error?.message);
      }

        let notesToUpdate = {
          contactId: currentContact._id ? currentContact._id : currentContact.id,
          notesAgent: notesAgent,
        };
        const { error: errorNotes } = await updateContactNotesAsync(notesToUpdate);

        if (errorNotes) {
          showUIToast({
            type: "error",
            text: errorNotes?.message,
          });
          setShowModal(false);
          setErrorBoundary(true);
          throw new Error(errorNotes?.message);
        }

      if (submittedInfo) {
        cleanDispositionInfo();
      } else {
        setShowAdditionalNotes(false);
        setShowModal(false);
        setCallTransferred(true);
        setIsOngoingCall(false);
        setCallId("");
        setNotesAgent("");
      }
    }
  };

  const contentContainerStyles: React.CSSProperties = {
    transition: 'filter 0.3s ease-in-out',
    filter: showModal ? 'blur(8px)' : 'none', // Aplica el difuminado si el modal está abierto
    pointerEvents: showModal ? 'none' : 'auto', // Desactiva la interacción con el fondo si el modal está abierto
  };

  return (
    <Layout
      handleLogout={logout}
      user={currentUser.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <div style={contentContainerStyles}>
      <MicrophoneSetupModal />
      {step === 1 && (
        <StartContainer
          onStart={async () => await startInterval()}
          intervals={intervals}
          setIntervals={setIntervals}
        />
      )}
      {step === 2 && (
        <>
          <ErrorBoundary
              fallBackComponent={<>NO, MURIÓ!!</>}
              resetCondition={
                  currentCallSession && currentContact && twilioDevice
              }
              error={errorBoundary}
          >
            <CallContainer
                handleToggleCall={handleToggleCall}
                transferCall={handleTransferCall}
                submittedInfo={submittedInfo}
                setSubmittedInfo={setSubmittedInfo}
                callId={callId}
                onPhone={onPhone}
                callStatus={currentCallSession?.status}
                twilioDevice={twilioDevice}
                voiceDropsIds={currentCallSession?.voiceDropsIds}
                speech={currentCallSession?.speech}
                isMuted={isMuted}
                toggleMute={toggleMute}
                isSdrFree={currentCallSession?.sdrStatus === "free"}
                currentContact={currentContact}
                setCurrentContact={setCurrentContact}
                callTransferred={callTransferred}
                callSessionId={currentCallSession?.id}
                cleanDispositionInfo={cleanDispositionInfo}
                submittedDisabled={submittedDisabled}
                setSubmittedDisabled={setSubmittedDisabled}
                setTransferDisabled={setTransferDisabled}
                isTransferDisabled={isTransferDisabled}
                setQueueElements={setQueueElements}
                isOngoing={isOngoingCall}
                setDialedNumber={setDialedNumber}
                lastCallId={lastCallId}
                friendlyNameVM={friendlyNameVM}
                setNotesAgent={setNotesAgent}
            />
          </ErrorBoundary>
        </>
        )}
        {step === 3 && (
            <CallFinishedContainer
                currentContact={currentContact}
          setCurrentContact={setCurrentContact}
        />
      )}
      </div>
      {showModal && (
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 }}>
            <DispositionsModal
                isOpen={showModal}
                setOpen={setShowModal}
                selectedOption={selectedOption}
                options={options}
                additionalNotes={notesAgent}
                setAdditionalNotes={setNotesAgent}
                showAdditionalNotes={showAdditionalNotes}
                onDropdownChange={onDropdownChange}
                onSubmit={handleSelectedOption}
                isDisabled={isDisabled}
            />
          </div>
      )}
    </Layout>
  );
};

export default DialerAgent;
