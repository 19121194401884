import {CSVContact} from "../../../../src-v2/Data/DataSource/contacts-nest/contacts/entities/contact.entity";

function countContactOccurrences(contacts: CSVContact[]) {
    const nameOccurrences: { [key: string]: number } = {};
    const phoneOccurrences: { [key: string]: number } = {};

    contacts.forEach((contact) => {
        const name = contact.name;
        const phone = contact.phone.trim();

        nameOccurrences[name] = (nameOccurrences[name] || 0) + 1;
        phoneOccurrences[phone] = (phoneOccurrences[phone] || 0) + 1;
    });

    return { nameOccurrences, phoneOccurrences };
}

const phoneRegex = /^\++\d{1,3}[\s.-]?\d{2}[\s.-]?\d{4}[\s.-]?\d{4}(?:\s*(?:ext|EXT|Ext)\.?\s*\d{1,5})?$|^\++\d{1,3}[\s.-]?\d{3}[\s.-]?\d{3}[\s.-]?\d{4}(?:\s*(?:ext|EXT|Ext)\.?\s*\d{1,5})?$|^\(\+?\d{1,3}\)[\s.-]?\d{3}[-.\s]?\d{3}[-.\s]?\d{4}(?:\s*(?:ext|EXT|Ext)\.?\s*\d+)?$|^\(\d{3}\)[\s.-]?\d{3}[-.\s]?\d{4}(?:\s*(?:ext|EXT|Ext)\.?\s*\d+)?$|^\d{3}[\s.-]?\d{3}[\s.-]?\d{4}$/

export function validateContacts(contacts: CSVContact[]) {
    const { nameOccurrences, phoneOccurrences } = countContactOccurrences(contacts);

    contacts.forEach((contact) => {
        // The error messages for each contact should be cleaned before being validated else all contacts will report errors.
        contact.errorMessages = [];
        const errors = [];
        const nameCount = nameOccurrences[contact.name];
        const phoneCount = phoneOccurrences[contact.phone.trim()];

        if (!phoneRegex.test(contact.phone.trim())) {
            errors.push('Invalid phone number format');
        }

        if (!contact.phone || contact.phone.trim() === ''){
            errors.push('No phone number!');
        }

        if (nameCount > 1) {
            if (!Object.keys(nameOccurrences).some(key => key === '')) {
                errors.push(`Contact name is repeated ${nameCount} times`);
            }
        }



        if (phoneCount > 1) {
            if (!Object.keys(phoneOccurrences).some(key => key === '')) {
                errors.push(`Phone number is repeated ${phoneCount} times`);
            }
        }

        if (errors.length > 0) {
            contact.errorMessages = errors;
        } else if (errors.length == 0){
            contact.errorMessages = [];
        }
    });

    return contacts;
}