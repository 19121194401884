import type { ApiResponse } from '@/src/types/ApiResponse';
import type { RemoveTokenDto } from '@datasource/auth/logout/dto/removeTokenDto';
import { removeTokenAsync } from '@datasource/auth/logout/logout-by-admin';

interface LogoutRepositoryProps {
  logout: (body: RemoveTokenDto) => Promise<ApiResponse<Record<string, string>>>;
}

export function LogoutRepository(): LogoutRepositoryProps {
  return {
    async logout(body): Promise<ApiResponse<Record<string, string>>> {
      const { data, error, success } = await removeTokenAsync(body);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}
